<template>
  <div>
    <!-- begin image src with "./" so it's imported as a vue 'static asset' -->
    <!-- https://cli.vuejs.org/guide/html-and-static-assets.html#static-assets-handling -->
    <a href="https://dornsife.usc.edu/bridge-institute/" target="_blank">
      <img
        v-if="displayWidth === 'narrow'"
        class="short"
        src="./img/Bridge-small.png"
        alt="USC Bridge Institute"
        title="USC Bridge Institute" />
      <img
        v-else
        class="long"
        src="./img/Bridge-lightMode.png"
        alt="USC Bridge Institute"
        title="USC Bridge Institute" />
      <!-- Bridge-lightMode.png | Bridge.png -->
    </a>
  </div>
</template>

<script setup>
import {inject} from "vue";
// seems more robust that using the style/CSS below
const displayWidth = inject("displayWidth"); // from composable/display.js
</script>

<!-- <style scoped>
.narrow img.long {
  display: none;
}
:is(.medium, .wide, .unlimited) img.short {
  display: none;
}
</style> -->
