<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div class="gcse-search" />
</template>

<script>
// https://cse.google.com/
export default {
  name: "SearchBox",
  // props: ['site'], - used in old (not working) version
  data() {
    return {
      text: ""
    };
  },
  mounted() {
    // https://stackoverflow.com/q/45047126
    let recaptchaScript = document.createElement("script");
    // https://stackoverflow.com/q/3408805
    recaptchaScript.setAttribute("async", true);
    recaptchaScript.setAttribute("src", "https://cse.google.com/cse.js?cx=6015dc15afa29e05e");
    document.head.appendChild(recaptchaScript);
  }
};
</script>
