<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div class="sponsor">
    <component :is="sponsor" />
  </div>
</template>

<script setup>
// eslint-disable-next-line
const props = defineProps({
  year: {default: 0, type: [Number, String]} // from App.vue
});
let sponsor = "assets/years/";
sponsor += `${props.year}/Sponsors/MainSponsor.vue`;
import {shallowRef} from "vue";
try {
  // require needs to start with "`@" = triggers webpack?
  sponsor = shallowRef(require(`@/${sponsor}`).default);
} catch (error) {
  console.error(`Problem reading MainSponsor.vue: ${error}`);
}
</script>

<style scoped>
.sponsor :deep(img) {
  /* ':deep()' applies to child components */
  /* https://stackoverflow.com/q/3029422 */
  /*max-width: 100%; /* ensures scaling with perserved aspect ratio  */
  max-height: 100%;
  max-width: clamp(35px, 24vw, 198px);
  vertical-align: middle; /* improves vertical alignment */
}
.narrow .sponsor :deep(img) {
  /* applies to child components */
  max-width: clamp(20px, 12vw, 40px);
}
</style>
