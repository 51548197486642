<template>
  <div>
    <!-- begin image src with "./" so it's imported as a vue 'static asset' -->
    <!-- https://cli.vuejs.org/guide/html-and-static-assets.html#static-assets-handling -->
    <a href="https://dornsife.usc.edu/bridge-institute/" target="_blank">
      <img class="short" src="./img/Bridge-Institute-short.png" alt="USC Bridge Institute" title="USC Bridge Institute">
      <img class="long" src="./img/Bridge-Gold+White.png" alt="USC Bridge Institute" title="USC Bridge Institute">
    </a>
  </div>
</template>

<style scoped>
  .narrow img.long, .medium img.long, .wide img.long  {
    /* hide long vesion on narrow screens */
    display: none;
  }
  .unlimited img.short {
    /* hide short vesion on unlimited screens */
    display: none;
  }
</style>
