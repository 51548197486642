// makes a call to the flickr API, retrieving one page of results

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

const imagesPerPage = 400; // 60 = Google image search; 500 = max. for flickr

const flickrRestCall = async function (page) {
  // this function handles the call the flickr
  let url = new URL("https://api.flickr.com/services/rest");
  let params = {
    // https://www.flickr.com/services/api/flickr.photos.search.html
    method: "flickr.photos.search",
    tags: "vizbi",
    sort: "date-taken-desc",
    api_key: "1f76f84d4503878c5dbc2b472c469cba", // config.api_key
    format: "json",
    extras: "url_n, url_l, url_o, owner_name, date_taken",
    page: page,
    per_page: imagesPerPage,
    nojsoncallback: 1,
  };
  // https://stackoverflow.com/q/37230555
  url.search = new URLSearchParams(params).toString();
  log(`url = ${url}`);
  try {
    const response = await fetch(url);
    if (!response.ok) {
      // https://dmitripavlutin.com/javascript-fetch-async-await/
      throw new Error("Fetch error " + response.status);
    }
    let responseJson = await response.json(); // critical: wait for json
    //log(`responseJson = ${stringify(responseJson)}`);
    return responseJson;
  } catch (error) {
    console.error(`Problem accessing flickr API: ${error}`);
  }
};

import { processFlickrSearchData } from "@/composables/useFlickrSearchData";

export async function fetchFlickrPage(page) {
  // this function handles states within this vue components
  try {
    let response = await flickrRestCall(page);
    if (!response) {
      return console.error(`No response from flickr API.`);
    }
    //log(`response = ${stringify(response)}`);
    let newPhotoData = await response.photos.photo;
    let output = processFlickrSearchData(newPhotoData);
    output.pagesTotal = await response.photos.pages;
    output.photosTotal = newPhotoData.length;
    log(`Fetched photo data page ${page}`);
    log(`Number of photos: ${output.photosTotal}`);
    return output;
  } catch (error) {
    console.error(`Error fetching flickr page ${page}: `, error);
  }
}
