<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <Burger v-if="displayWidth === 'narrow'" :views="views" :selected="view" />
  <PrimaryMenu v-else :views="views" :selected="view" />
</template>

<script setup>
const log = (message) => console.log(); // eslint-disable-line
import {inject} from "vue";
const displayWidth = inject("displayWidth"); // from composable/display.js
import Burger from "@/components/Navigation/Burger.vue";
import PrimaryMenu from "@/components/Navigation/Primary/Menu.vue";
// setup primary views
import {ref} from "vue";
const views = ref(["About", "Blog", "Photos", "Posters", "Videos"]);
// add current year
import history from "@/assets/history.json";
views.value.push(history.latestYear);
// determine currently selected view
const view = ref(views.value.slice(-1)[0]); // initially, set to current year
import {useRoute} from "vue-router";
const route = useRoute();
function updateSelectedView() {
  log(`updateSelectedView()`);
  if (!route.name) return;
  log(`updateSelectedView: route name = ${route.name}`);
  if (route.name.includes("Video")) return "Videos"; // title fixed in App.ue
  if (!route.name.includes("Year")) {
    // allows 'TestYear'
    if (!views.value.includes(route.name)) return;
    return route.name;
  }
  log(`updateSelectedView: route should be a year`);
  if (!route.params.year) return;
  log(`updateSelectedView: route year = ${route.params.year}`);
  if (!history.years[route.params.year]) return; // year not in history
  return route.params.year;
}
view.value = updateSelectedView(); // invoke initially
// recalculate selected view when route changes
import {watch} from "vue";
watch(route, () => {
  view.value = updateSelectedView(); // re-run upon route changes
});
</script>

<style scoped></style>
