<template>
  <h3>Join VIZBI Mailing List</h3>
  <ol>
    <li>Sign up below to receive email notification of major announcements related to VIZBI.</li>
    <div id="mail-form" :class="`_form_${formID}`" />
    <li>Add <a href="mailto:contact@vizbi.org">contact@vizbi.org</a> to your address book to ensure VIZBI emails never get sent to spam.</li>
    <li style="margin-top: 20px">
      For more frequent VIZBI news, follow the
      <a href="https://vizbi.org/blog/">VIZBI blog</a> using <a href="https://vizbi.org/blog/feed/">RSS</a>, <a href="http://twitter.com/vizbi">Twitter</a>, <a href="http://friendfeed.com/vizbi">FriendFeed</a>, or <a href="http://www.facebook.com/pages/VIZBI/103403666363639">Facebook</a>.
    </li>
  </ol>
</template>

<script setup>
const log = (message) => console.log(); // eslint-disable-line
import {inject} from "vue";
const store = inject("store"); // from App.vue
let continent = store.state.currentContinent || "";
import {ref} from "vue";
const formID = ref(null);
import {onBeforeMount} from "vue";
onBeforeMount(() => {
  log("Mail beforeMount()");
  // https://stackoverflow.com/q/45047126
  let recaptchaScript = document.createElement("script");
  recaptchaScript.async = true;
  // https://stackoverflow.com/q/3408805
  //recaptchaScript.setAttribute('async', true);
  if (continent === "europe") {
    formID.value = 13;
  } else {
    // form for america
    formID.value = 15;
  }
  let url = `https://masteryevents.activehosted.com/f/embed.php?id=`;
  url += formID.value;
  recaptchaScript.setAttribute("src", url);
  document.head.appendChild(recaptchaScript);
  var trackcmp_email = "";
  var trackcmp = document.createElement("script");
  trackcmp.async = true;
  trackcmp.type = "text/javascript";
  trackcmp.src = "//trackcmp.net/visit?actid=251984889&e=" + encodeURIComponent(trackcmp_email) + "&r=" + encodeURIComponent(document.referrer) + "&u=" + encodeURIComponent(window.location.href);
  var trackcmp_s = document.getElementsByTagName("script");
  if (trackcmp_s.length) {
    trackcmp_s[0].parentNode.appendChild(trackcmp);
  } else {
    var trackcmp_h = document.getElementsByTagName("head");
    trackcmp_h.length && trackcmp_h[0].appendChild(trackcmp);
  }
});
</script>

<style scoped>
#mail-form {
  float: left;
  width: 100%;
}
._form-thank-you {
  color: red;
}
li {
  clear: both; /* active campaign uses floats that need to be cleared */
}
</style>
