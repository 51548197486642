<template>
  <p>
    We invite all in-person and remote participants to present a
    <router-link to="#submissions">Scientific Poster</router-link> and a 60 second <router-link to="#lightning">Lightning Talk</router-link> describing work on biological data visualization (we cannot accept submissions focused on advertising). Participants can also upload one biologically-inspired artwork for the <router-link to="#art">Art &amp; Biology Event</router-link>. We encourage participants to consider enhancing their poster or artwork with augmented reality content (<router-link to="#AR">see below</router-link>
    for details).
  </p>
  <p>
    To upload your poster or artwork, you need to first
    <router-link to="/2023/Registration/">register for the workshop</router-link>. You will then receive a confirmation email containing a link to the upload page.
  </p>
  <p>The deadline for submitting posters and artwork is 11pm PST on <b>March 6, 2023</b>.</p>
  <h4 id="AR">Augmented Reality (AR) posters and artwork</h4>
  <p>
    For VIZBI 2023 we have partnered with <a href="https://www.schol-ar.io">Schol-AR</a> to let participants augment their poster or artwork with interactive content (videos, image stacks, 3D models, or volumetric data). To learn more, read the
    <!-- <a href="https://www.schol-ar.io/publications/ard2022 " -->
    <a href="https://www.nature.com/articles/s41597-022-01426-y">Schol-AR paper</a>, or see the video below:
  </p>
  <p>
    <iframe src="https://www.youtube.com/embed/aaw0Sz7zy5c" title="YouTube video player" allowfullscreen="1" />
  </p>
  <p>
    The augmented content on printed posters or artwork can then be easily explored using most mobile devices. If your device has issues, please try installing the latest OS; on Android phones you may need to install
    <a href="https://play.google.com/store/apps/details?id=com.google.ar.core&hl=en_US&gl=US&pli=1">Google Play Services for AR</a>). A selection of the augmented posters and artwork may remain on display at EMBL for several months following the meeting.
  </p>
  <p>
    To help you with augmenting your poster or artwork, there will be a live
    <b>Schol-AR Demo / Q&amp;A Session</b> hosted by <a href="https://keck.usc.edu/faculty-search/tyler-ard/">Tyler Ard</a>, the creator of Schol-AR, on <b>Feb. 7, 2023, 6:00pm (CET)</b>. If you wish to participate, please
    <a href="https://www.eventbrite.com/e/schol-ar-demo-qa-session-for-ar-posters-at-vizbi-2023-tickets-521494402797" noreferrer>sign-up on for this event on Eventbrite</a>
    to get the Zoom link.
  </p>

  <h4 id="submissions">Poster Submission</h4>
  <p>Please prepare one main image in PNG format (&#8804; 50 MB). Also prepare an abstract describing your work (single paragraph, &#8804; 1,200 characters), ideally in language accessible to scientists who are not specialists in your particular domain.</p>
  <p>Once your poster is ready, upload it using the link provided in the email registration confirmation email (upload deadline: <b>March 6, 2023</b>). Immediately after uploading, you will be able to preview a zoomable version of your poster, showing how it will appear on the VIZBI poster pages (for an example, see <a href="https://vizbi.org/Posters/2010/F06">here</a>). Please take this opportunity to verify that the title, abstract, affiliations, and poster image are in good order - if not, simply re-upload a modified version (this will overwrite your previous submission). Our policy is to accept all posters relevant to the scientific themes of the conference. We will notify you shortly after the upload deadline in case there are any policy or technical issues with your submission.</p>
  <p>
    If accepted, your submission will be published as part of the
    <a href="/Posters/">VIZBI poster collection</a> on the first day of the conference.
  </p>

  <h4 id="on-site">Printing &amp; displaying your poster on-site</h4>
  <p><strong>Finding your poster's session.</strong> If you are attending VIZBI 2023 in-person, your poster will have been assigned a code beginning with a letter corresponding to its scheduled session.<br />Session codes are: A - Wednesday Lunch, B - Wednesday Evening, C - Thursday Lunch, D - Friday Lunch.</p>
  <p><strong>Putting up your poster.</strong> Posters should be either A0 portrait or A1 landscape format. Boards are numbered (corresponding to the numeric part of your poster's code). Laptop tables will also be available for you to use, as well as a limited number of large display screens. Please indicate on the poster upload form if you require a large display screen and we will do our best to ensure one is available for you during your session.</p>
  <p><strong>When to put up your poster.</strong> We recommend you put up your poster during the coffee-break preceding your session. That way, your poster will already be on display at the beginning of the lightning talks for your session.</p>

  <h4 id="lightning">Lightning Talks</h4>
  <p>Each scientific poster session is preceded by a &lsquo;Lightning Talk&rsquo;, where you come on stage, introduce yourself, and give the audience a quick overview of your poster. Since you have only 1 minute to address the audience, you are encouraged to prepare well and be creative in order to get your message across. In preparing your slides, please note the following:</p>
  <ul>
    <li>You can upload up to three images to accompany your presentation.</li>
    <li>Each image should be in PNG format and be designed to fit onto a screen with aspect ratio of 16:9. The projector supports a resolution of up to 2560 &#215; 1440.</li>
  </ul>
  <p>Remote participants should not submit images, but instead upload a video that will then be screened during the Lightning session (see <a href="#Virtual">below</a>). Note that normal participants cannot submit videos.</p>

  <h4 id="virtual">Remote Participants</h4>
  <p>Remote participants can present their work by uploading a poster together with a ≤60 second video. We encourage you to be creative to get your message across effectively in this short time. Please note the following:</p>
  <ul>
    <li>Supported video formats are AVI, MP4 or MOV.</li>
    <li>Maximum Resolution: 1920 × 1080</li>
    <li>Maximum File Size: 250 MB</li>
    <li>Videos longer than 60s will not be accepted by the upload system.</li>
    <li>After upload, you will be directed to a unique URL that allows you to preview and test exactly how your lightning talk video will appear.</li>
  </ul>
  <p>Your video will be screened during one of the Lightning Talk sessions, immediately before your poster session. During the poster session, you can request to use a 'virtual presentation display' co-located with the physical posters. This will enable a limited number of remote participants to interact via Zoom with in-person participants that visit your display, as well as with remote participants.</p>
  <p>In any case, to enable VIZBI participants to contact you, we recommend indicating your availability throughout the meeting and including contact details (such as a Skype identifier), either in your video as part of your poster abstract.</p>

  <h4 id="art">Art &amp; Biology entries</h4>
  <p>We invite each workshop participant to submit one biologically-inspired art image, as part of our Art &amp; Biology Award. We can only accept entries clearly related to the themes of the conference that do not violate policies of our host. Accepted entries will first be revealed as part of <a href="http://vizbi.org/Posters/?category=Art">Art &amp; Biology evening event</a>. The award for best Art &amp; Biology entry will be decided by popular vote and announced at the Awards Ceremony during the conference closing session. Participants are asked to judge Art &amp; Biology entries based on how visually compelling and original they are in communicating biological insight; it may help to see Art &amp; Biology entries from <a href="http://vizbi.org/Posters/?category=Art">previous VIZBI meetings</a>. All Art &amp; Biology entries will be published on the VIZBI website, alongside the scientific posters.</p>

  <h4 id="ar-art">Augmented artwork</h4>
  <p>Artwork augmented using <a href="https://www.schol-ar.io">Schol-AR</a> will be displayed in a special exhibit throughout the VIZBI workshop. Please ensure your artwork can be printed within about 30 ✕ 40 inches ideally at 120 DPI. When preparing your augmented artwork, be sure that the Schol-AR QR code is incorporated into the final image that you upload. Note that augmented images in scientific posters will not be part of the exhibit. If you would like to feature an image from your poster in the exhibit, please submit it as a separate art &amp; biology artwork.</p>
  <HeroImage file="2023/Posters/img/AR-exhibit.jpg" credit="Christian Stolte" />
</template>

<script>
import HeroImage from "@/components/HeroImage.vue";
export default {
  components: {HeroImage}
};
</script>

<style scoped>
ul {
  list-style-type: circle;
  padding-left: 18px;
}
li {
  margin-bottom: 6px;
}
iframe {
  width: 560px;
  height: 315px;
  max-width: 100%;
  border: none;
}
</style>
