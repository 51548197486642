<template>
  <p>Interactive media, immersive virtual reality (VR), and augmented reality (AR) have the potential to transform our approaches to learning about, exploring, and communicating research in the life sciences. For the first time, VIZBI will feature an exhibit of life science-themed apps and games using 2D, AR, and VR display technologies.</p>
  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdzd-V6H71Tt0UZfQb4xuDXzgtcVAdrXfBChBjXobht4bYHjw/viewform" title="Click to open the VR Showcase contribution form">
    <HeroImage file="2023/VR/img/banner.jpg" credit="Emmanuel Reynaud" />
  </a>
  <p>The idea is to give attendees the opportunity to experience these technologies for themselves, learn about their applications in research and education, and meet scientists who are developing content.</p>
  <p>If you are a researcher, artist, or developer working in interactive media or extended reality such as VR or AR, please sign up via the button below to get involved by either:</p>
  <ul>
    <li>Asking us to feature your work</li>
    <li>Bringing headsets or other VR/AR equipment to Heidelberg to help showcase other content</li>
    <li>Helping with the exhibit during the conference</li>
  </ul>
  <ButtonBespoke :outstanding="true" onclick="window.open('https://docs.google.com/forms/d/e/1FAIpQLSdzd-V6H71Tt0UZfQb4xuDXzgtcVAdrXfBChBjXobht4bYHjw/viewform','_blank', 'noreferrer')" title="Click to open the VR Showcase contribution form">
    <!-- https://vuejs.org/guide/essentials/template-syntax.html -->
    Sign Up to Get Involved...
  </ButtonBespoke>
  <p>If you aren't already working on interactive media or extended reality content, you can still get involved by using Schol-AR to augment your VIZBI 2023 <router-link :to="{ path: '/2023/Posters', hash: '#AR' }">poster or artwork</router-link>.</p>
</template>

<script>
// import { opendir } from 'fs'; // Jim, I guess this isn't needed?
import ButtonBespoke from "@/components/ButtonBespoke";
import HeroImage from "@/components/HeroImage.vue";
export default {
  components: { ButtonBespoke, HeroImage },
  props: {
    // comment out the line below when we're ready to publish this page
    // devOnly: {type: Boolean, default: true}
  },
};
</script>

<style scoped>
ul {
  list-style-type: circle;
  padding-left: 18px;
}
li {
  margin-bottom: 6px;
}
img {
  width: 100%;
}
iframe {
  width: 560px;
  height: 315px;
  max-width: 100%;
  border: none;
}
</style>
