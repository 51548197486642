<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div>
    <p>Redirecting...</p>
  </div>
</template>

<script>
export default {
  props: {
    // https://stackoverflow.com/q/51671818
    selectedYear: {
      default: 0,
      type: [Number, String]
    }
  },
  mounted() {
    // console.log('URL points to a page not avaialble');
    // console.log('Redirecting to the selected year');
    console.log("NotFound.vue: REDIRECTING TO HOME");
    this.$router.push("/");
    // this.$router.push('/' + this.selectedYear); - zero error
  }
};
</script>
