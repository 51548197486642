// https://www.youtube.com/watch?v=_k4GM5cmm68
// store: history, currentYear, currentYearPast, signIn info
import {reactive} from 'vue';

const state = reactive({
  counter: 0
});

const methods = {
  decreaseCounter() {
    state.counter--;
  },
  increaseCounter() {
    state.counter++;
  }
};

export default {
  state,
  methods
};
