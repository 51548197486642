<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <PhotoHighlight v-if="image && image.url" :image="image" @hide="hide" />
  <PhotoHighlight v-else-if="imageFromHash.url" :image="imageFromHash" @hide="hide" />
</template>

<script setup>
import PhotoHighlight from "@/components/PhotoHighlight";
const props = defineProps(["image"]);
const emit = defineEmits(["hide"]);

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

import {onBeforeMount, reactive} from "vue";
const imageFromHash = reactive({});
import {fetchFlickrInfo} from "@/composables/useFlickrGetInfo";
onBeforeMount(async () => {
  if (window.location.hash) {
    log(`called with hash in url`);
    let path = window.location.hash.substring(1); // remove '#'
    let id = path.match(/\w+\/(\d+)_\w+\.png/);
    if (!id) {
      // exit if not similar to '65535/52852568788_51130d8086_n.jpg'
      log(`hash does not match an flickr image url`);
      setUrlHash(); // remove hash
      return;
    }
    imageFromHash.url = `https://live.staticflickr.com/${path}`;
    imageFromHash.id = id[1];
    let flickrInfo = await fetchFlickrInfo(id[1]);
    if (flickrInfo) {
      log(`flickrInfo = ${JSON.stringify(flickrInfo)}`);
      imageFromHash.owner = flickrInfo.owner.nsid;
      imageFromHash.ownername = flickrInfo.owner.realname;
    }
  }
});

import {watch} from "vue";
watch(
  () => props.image,
  () => {
    if (props.image && props.image.url) {
      log(`image defined in props: ${props.image.id}, ${props.image.owner}, ${props.image.ownername}, ${props.image.url}`);
      setUrlHash(props.image.url.replace(/.*\.com\//, ""));
      return;
    }
  }
);

const hide = function () {
  log(`photoHighlightState/hide()`);
  emit("hide"); // disables highlight
  setUrlHash(); // clear URL
  // clear properties from imageFromHash object
  Object.keys(imageFromHash).forEach((key) => delete imageFromHash[key]);
};

const setUrlHash = function (hash) {
  let path = window.location.pathname;
  if (hash) {
    history.pushState({}, null, `${path}#${hash}`);
  } else {
    history.pushState({}, null, path);
  }
};
</script>
