<!-- Copyright: Seán I. O'Donoghue -->
<!-- TODO: styling for hover on dropdowns -->

<template>
  <nav>
    <h3>
      Videos
      <span v-if="$route.params.vimeoHash" class="count"> [1/{{ totalVideos }}] </span>
      <span v-else-if="totalVideos && filteredVideos" class="count"> [{{ filteredVideos }}/{{ totalVideos }}] </span>
      <span v-else-if="totalVideos" class="count"> [{{ totalVideos }}] </span>
    </h3>
    <div id="right" :class="{reverse: !showYear}">
      <Dropdown
        v-if="showYear"
        placeholder="Year"
        :tag="$route.params.vimeoHash ? false : true"
        :options="years"
        :selected="year"
        path="/Videos" />
      <Dropdown
        v-if="showSession"
        placeholder="Session"
        :tag="$route.params.vimeoHash ? false : true"
        :options="sessions"
        :selected="session"
        path="/Videos" />
      <ButtonBespoke v-if="year || session" id="showAll" @click="showAll">Show All</ButtonBespoke>
    </div>
  </nav>
  <VideoLegend :showcases="Object.keys(showcases)" />
  <router-view v-slot="{Component}">
    <component :is="Component" :options="options" @filterByYear="filterByYear" @filterBySession="filterBySession" />
  </router-view>
</template>

<script>
import ButtonBespoke from "@/components/ButtonBespoke";
import Dropdown from "@/components/DropdownRoutes/Dropdown";
import showcasesFromJson from "@/assets/videos/showcases.json";
import VideoLegend from "@/components/VideoLegend";
import {inject, ref, onMounted} from "vue";
const reactiveDisplayWidth = ref(null);
export default {
  components: {
    ButtonBespoke,
    Dropdown,
    VideoLegend
  },
  setup() {
    const displayWidth = inject("displayWidth");
    onMounted(() => {
      reactiveDisplayWidth.value = displayWidth.value;
      console.log(`displayWidth = ${reactiveDisplayWidth.value}`);
    });
    return {reactiveDisplayWidth};
  },
  data() {
    return {
      got_showcase: {}, // remember video showcase
      got_privacy: {}, // remember video privacy
      thumbnails: {},
      registered_events: {},
      showcases: showcasesFromJson, // assign json to data property
      showYear: true,
      showSession: true,
      session: this.$route.params.session || "", // empty string = all session
      year: this.$route.params.year || "" // .toString() - empty string = all years
    };
  },
  computed: {
    options() {
      return {
        get_showcase: this.get_showcase,
        get_privacy: this.get_privacy,
        showcases: this.showcases,
        thumbnails: this.thumbnails,
        filteredShowcases: this.filteredShowcases,
        year: this.year,
        session: this.session
      };
    },
    dev_server() {
      if (window.location.host.match(/^dev.vizbi.org/)) {
        // on dev.vizbi.org, show staged videos
        return true;
      } else {
        return false;
      }
    },
    localhost() {
      if (window.location.host.match(/^localhost|^192/)) {
        // on localhost, show the very latest Showcase
        return true;
      } else {
        return false;
      }
    },
    years() {
      let years = []; // 'All'
      Object.keys(this.showcases).forEach(function (year) {
        // Accumulate list of years
        year = year.split(" ")[1];
        if (!years.includes(year)) {
          years.push(year);
        }
      });
      return years;
    },
    sessions() {
      let sessions = []; // 'All'
      var _this = this;
      Object.keys(this.showcases).forEach(function (year) {
        // Accumulate list of sessions
        Object.keys(_this.showcases[year].videos).forEach(function (hash) {
          let session = _this.showcases[year].videos[hash].tag;
          if (session && !sessions.includes(session)) {
            sessions.push(session);
          }
        });
      });
      return sessions;
    },
    totalVideos() {
      return this.countVideosIn(this.showcases);
    },
    filteredShowcases() {
      if (this.$route.params.vimeoHash) return {};
      this.log(`filterShowcases(${this.session}, ${this.year})`);
      var _this = this;
      var filteredShowcases = {};
      var value;
      if (this.session) {
        // Retrieve list of session tags for the dropdown menu
        Object.keys(_this.showcases).forEach(function (year) {
          Object.keys(_this.showcases[year].videos).forEach(function (hash) {
            value = _this.showcases[year].videos[hash].tag;
            if (value === _this.session) {
              if (!filteredShowcases[year]) {
                filteredShowcases[year] = {};
                filteredShowcases[year].videos = {};
              }
              filteredShowcases[year].videos[hash] = _this.showcases[year].videos[hash];
            }
          });
        });
      } else if (this.year) {
        Object.keys(_this.showcases).forEach(function (item) {
          value = item.split(" ")[1];
          if (value === _this.year) {
            filteredShowcases[item] = _this.showcases[item];
          }
        });
      } else {
        filteredShowcases = this.showcases;
      }
      this.log(`filteredShowcases = ${Object.keys(filteredShowcases).length}`);
      return filteredShowcases;
    },
    filteredVideos() {
      let result = this.countVideosIn(this.filteredShowcases);
      if (result !== this.totalVideos) {
        return result;
      } else {
        return 0; // computed can't return 'undefined' -
      }
    }
  },
  async mounted() {
    this.log("Videos mounted()");
    if (this.$route.params.vimeoHash) {
      await this.get_showcase(this.$route.params.vimeoHash); // don't need?
      this.log(`reactiveDisplayWidth.value = ${reactiveDisplayWidth.value}`);
      if (reactiveDisplayWidth.value === "narrow") {
        this.log(`displayWidth is narrow`);
        [this.showYear, this.showSession] = [false, false];
      }
      return;
    }
    if (this.$route.params.year) {
      [this.showYear, this.showSession] = [true, false];
      return;
    }
    if (!this.$route.params.session) return;
    if (!this.sessions.includes(this.$route.params.session)) {
      this.log(`URL has invalid session name`);
      this.showAll();
      return;
    }
    [this.showYear, this.showSession] = [false, true];
  },
  methods: {
    // eslint-disable-next-line
    log(message) {
      if (process.env.NODE_ENV === "development") {
        console.log(message); // uncomment to show logs from this component
      }
    },
    get_privacy(vimeoHash, showcase) {
      if (this.got_privacy[vimeoHash]) {
        return this.got_privacy[vimeoHash]; // if privacy is already known
      }
      if (!showcase) {
        // if called without defining showcase, look it up
        showcase = this.get_showcase(vimeoHash);
      }
      let privacy;
      if (!showcase || !this.showcases[showcase]) {
        privacy = false; // unlisted video, so privacy is unknown
      } else if (!this.showcases[showcase]["videos"][vimeoHash]) {
        privacy = false; // privacy is unknown
      } else if (!this.showcases[showcase]["videos"][vimeoHash]["privacy"]) {
        privacy = false; // privacy is unknown
      } else {
        privacy = this.showcases[showcase]["videos"][vimeoHash]["privacy"] !== "anybody";
      }
      this.got_privacy[vimeoHash] = privacy; // remember privacy of this video
      return privacy;
    },
    filterByYear(year) {
      this.log(`filterByYear: '${year}'  (called from Tag)`);
      if (year === "All" || year === "") return this.showAll();
      this.$router.push(`/Videos/${year}`);
      // if (!this.$route.params.vimeoHash) {
      //   this.year = year;
      //   [this.showYear, this.showSession] = [true, false];
      //   this.session = "";
      // }
    },
    filterBySession(session) {
      this.log(`filterBySession: '${session}' (called from Tag)`);
      if (session === "All" || session === "") return this.showAll();
      this.$router.push(`/Videos/${session}`);
      // this.session = session;
      // [this.showYear, this.showSession] = [false, true];
      // this.year = "";
    },
    showAll() {
      this.log(`Show all years and sessions`);
      this.$router.push(`/Videos`);
      // this.year = "";
      // this.session = "";
      // [this.showYear, this.showSession] = [true, true];
      // if (this.$route.params.vimeoHash) return this.$router.push(`/Videos`);
      // history.pushState({}, null, `/Videos`); // silent
    },
    get_showcase(vimeoHash) {
      this.log(`get_showcase(${vimeoHash})`);
      let showcase; // local variable
      if (!vimeoHash) {
        console.warn(`vimeoHash not found`);
        return;
      }
      if (!this.showcases) {
        console.warn(`this.showcases not found`);
        return;
      }
      if (!Object.keys(this.showcases)) {
        console.warn(`No keys in this.showcases`);
        return;
      }
      if (Object.keys(this.showcases).length === 0) {
        console.warn(`No keys in this.showcases`);
        return;
      }
      if (this.got_showcase[vimeoHash]) {
        // if already found, use previous value
        return this.got_showcase[vimeoHash];
      }
      this.log(`About to check ${Object.keys(this.showcases).length} filteredShowcases`);
      for (showcase in this.showcases) {
        this.log(`Checking showcase: ${showcase}`);
        if (this.showcases[showcase]["videos"][vimeoHash]) {
          this.log(`Found showcase = ${showcase}`);
          let match = / (\d+)$/.exec(showcase);
          if (match[1]) {
            this.year = match[1];
            this.log(`Set year: "${this.year}"`);
          }
          this.session = this.showcases[showcase]["videos"][vimeoHash]["tag"];
          this.log(`Set session: "${this.session}"`);
          this.thumbnails = this.showcases[showcase]["videos"][vimeoHash]["pictures"];
          break; // end for loop
        } // otherwise, keep iterating over filteredShowcases
      }
      //return this.showcase;
      if (!showcase) {
        console.warn(`Missing needed meta data; returning to /Videos page`);
        showcase = ""; // for unlisted videos
        history.pushState({}, null, `/Videos`); // silent
      }
      this.got_showcase[vimeoHash] = showcase; // remember video showcase
      return;
    },
    countVideosIn(showcases) {
      // count total number of videos in a group of showcases
      let result = 0;
      for (let showcase of Object.values(showcases)) {
        // this.log(`result = ${result}`);
        // this.log(`showcase = ${Object.keys(showcase.videos).length}`);
        result += Object.keys(showcase.videos).length;
        // this.log(`result = ${result}`);
      }
      return result;
    }
  }
};
</script>

<style scoped>
nav {
  display: flex;
  /* align-items: flex-start; */
  align-items: center;
}
h3 {
  display: inline;
  margin-bottom: 4px;
}
h3 span {
  font-weight: 100;
  font-size: 1rem;
  /* vertical-align: middle; */
}
.medium .count,
.narrow .count {
  display: none; /* mobile first */
}
#right {
  display: flex;
  align-items: flex-start; /* prevent from changing height*/
  gap: 0.8rem;
  /* margin-top: 0.2rem; */
  margin-left: auto;
}
#right.reverse {
  direction: rtl;
}
/* .narrow #showAll {
display: none;
} */
a {
  margin-right: 20px;
}
</style>
