<template>
  <!-- <h4>Primary sponsor</h4>-->
  <!--<h4>Co-sponsors</h4>-->
  <div>
    <a href="https://www.biologists.com/" rel="nofollow">
      <img src="./img/CoB.svg" alt="CoB" title="The Company of Biologists" />
    </a>
    <a href="https://www.frontiersin.org/journals/bioinformatics" rel="nofollow">
      <img src="./img/Frontiers.png" alt="FBINF" title="Frontiers in Bioinformatics" class="frontiers" />
    </a>
  </div>
  <h4>Affiliations</h4>
  <div>
    <a href="https://www.embl.de/" rel="nofollow">
      <img src="./img/embl.svg" alt="EMBL" title="EMBL" />
    </a>
    <a href="http://www.iscb.org/" rel="nofollow">
      <img src="./img/ISCB.jpeg" alt="ISCB" title="ISCB" />
    </a>
    <a href="https://www.eg.org/" rel="nofollow">
      <img src="./img/EG.svg.png" alt="Eurographics" title="Eurographics" />
    </a>
  </div>
  <h4>Media Partners</h4>
  <div>
    <a href="http://www.digizyme.com/" rel="nofollow">
      <img src="./img/Digizyme.png" alt="Digizyme" />
    </a>
    <a href="https://www.embopress.org/journal/14693178">
      <img src="./img/EMBO-Reports.png" alt="EMBO Reports" title="EMBO Reports, an EMBO Press journal" />
    </a>
    <a href="https://iubmb.org/">
      <img src="./img/iubmb.jpeg" alt="IUBMB" title="International Union of Biochemistry and Molecular Biology" />
    </a>
    <a href="https://peerj.com/">
      <img src="./img/PeerJ.png" alt="PeerJ" title="PeerJ" />
    </a>
    <a href="http://rsob.royalsocietypublishing.org/">
      <img src="./img/rsob.png" alt="Open Biology" title="Open Biology, a Royal Society journal" class="openBiology" />
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      host: window.location.host
    };
  }
};
</script>

<style scoped>
/* Styles in this page are set in views/Sponsors.vue using the deep selector */
/* Some logos need individual adjustments */
/* https://stackoverflow.com/q/3029422 */
img.openBiology {
  max-height: 35px;
}
</style>
