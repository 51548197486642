<template>
  <p>For all inquiries relating to registration or sponsorship opportunities, please contact the EMBL conference office:</p>
  <p><Email to="embl-events" :outstanding="true" />.</p>
  <p>For inquiries relating to the program, please contact the conference chairs:</p>
  <p><Email to="vizbi-contact" :outstanding="true" />.</p>
</template>

<script>
import Email from '@/components/Email.vue';
export default {
  components: {Email}
};
</script>
