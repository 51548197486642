<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <section>
    <div class="leftColumn">
      <router-link v-if="item.name" class="item" :to="`/2023/Masterclass#${item.name.replaceAll(' ', '_')}`">
        <Suspense>
          <PersonHeadshot :person="item.name" :folder="`years/${year}/People/img/large`" :extension="'jpg'" />
          <!-- @click="goToPerson(item.name)" -->
        </Suspense>
      </router-link>
      <p v-else-if="item.description" class="description">
        {{ item.description }}
      </p>
    </div>
    <div class="rightColumn">
      <!-- not sure if timeStamp is right format -->
      <time :datetime="item.startTimeStamp">
        {{ `${item.date}, ${item.start} - ${item.end}` }}
      </time>
      <h4>'{{ item.title }}'</h4>
      <h5>{{ item.name }}</h5>

      <div v-if="false">
        <p>title = {{ item.title }}</p>
        <p>waiting-room-open = {{ waitingRoomOpen }}</p>
        <p>live = {{ live }}</p>
        <p>ended = {{ ended }}</p>
        <p>name = {{ item.name }}</p>
      </div>

      <div class="fieldAndCounter">
        <fieldset>
          <legend v-if="live">Live now</legend>
          <legend v-else-if="ended">Ended</legend>
          <legend v-else>Starts in</legend>
        </fieldset>
      </div>
      <div class="countdown">
        <Countdown v-if="showCounter" :until="item.startTimeStamp" @hideCounter="showCounter = false" />
      </div>
      <div v-if="live || ended || waitingRoomOpen" class="fieldAndCounter">
        <span>
          <VideoLink
            :waiting-room-open="waitingRoomOpen"
            :live="live"
            :ended="ended"
            :person="item.name"
            :event-title="item.title"
            :stream="item.stream"
            :video="item.video" />
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import Countdown from "@/components/Countdown";
const {DateTime, Interval} = require("luxon");
import {defineAsyncComponent} from "vue";
const PersonHeadshot = defineAsyncComponent(() => import("@/components/People/Person/Headshot.vue"));
//import stringify from "json-stringify-safe";
import VideoLink from "@/components/VideoLink";
export default {
  // eslint-disable-next-line
  name: "Event",
  components: {Countdown, PersonHeadshot, VideoLink},
  props: {
    item: {type: Object, default: () => {}},
    showLinkAt: {type: Number, default: 15}
  },
  emits: ["ended"],
  data() {
    return {
      ended: false,
      live: false,
      waitingRoomOpen: false,
      showCounter: true,
      now: DateTime.local(),
      year: 2024
    };
  },
  watch: {
    now() {
      //this.log(`endTimeStamp = ${stringify(this.item.endTimeStamp)}`);
      if (this.ended) {
        return;
      } // early exit > ensures only one emission
      if (this.now >= this.item.endTimeStamp) {
        this.live = false;
        this.ended = true;
        this.$emit("ended", this.item.startTimeStamp);
      } else if (this.now >= this.item.startTimeStamp) {
        this.live = true;
        this.waitingRoomOpen = false;
      } else {
        // event not started; check if it's time to open waiting room
        let interval = Interval.fromDateTimes(this.now, this.item.startTimeStamp);
        //this.waitingRoomOpen = interval.length('minutes') <= this.showLinkAt;
        if (interval.length("minutes") <= this.showLinkAt) {
          //this.log(`interval = ${interval.length('minutes')}`);
          this.waitingRoomOpen = true;
        }
      }
    }
  },
  mounted() {
    this.tick = this.startTimer();
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        console.log("tab now inactive - temporarily clear timer");
        clearInterval(this.tick); // stops 'now' from updating
      } else {
        console.log("tab is active again, so restarting timer");
        this.tick = this.startTimer();
      }
    });
  },
  beforeUnmount() {
    // Vue 3
    // beforeDestroy() { // Vue 2
    clearInterval(this.tick);
  },
  methods: {
    log(message) {
      // eslint-disable-line
      if (process.env.NODE_ENV === "development") {
        console.log(message); // uncomment to show logs from this component
      }
    },
    startTimer() {
      return setInterval(() => {
        // updates 'now' to local time every 0.1 second
        this.now = DateTime.local();
      }, 100);
    }
  }
};
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column-reverse; /* sets main axis as vertical */
  gap: 20px; /* vertical spacing between children */
  padding: 20px; /* separates border from children */
  align-items: center; /* aligns children + text within children */
  border-width: 1px;
  border-radius: 12px;
  border-color: #d9d9d9;
  background-color: #f1f1f1;
}
.wide section,
.unlimited section {
  /* on wide screens, switch to rows */
  flex-direction: row;
}
section > * {
  /* selects direct children of section > left & right columns */
  /* https://stackoverflow.com/q/43739470 */
  flex: 1; /* give each child approximately equal height */
  min-width: 0; /* prevents from overflowing */
}
div.rightColumn {
  display: flex;
  flex-direction: column; /* sets main axis as vertical */
  gap: 12px; /* vertical spacing between children */
  align-items: stretch; /* fill horizontal space */
}
div.rightColumn > * {
  /* selects all direct children > parts of right column */
  flex-grow: 1;
  flex-basis: 0;
  margin: 0px;
  padding: 0px;
  min-width: 0; /* prevents from overflowing */
}
time {
  display: block;
  color: #929292;
  text-align: center;
}
h4 {
  /* title */
  text-align: center;
  font-weight: 700;
}
h5 {
  /* name */
  color: gray; /* a bit darker gray; */
  text-align: center;
  font-weight: 600;
}
p {
  /* description */
  text-align: left;
  text-decoration: none;
  font-size: 1.1rem;
  color: black;
  align-items: stretch; /* fill horizontal space */
}
div.fieldAndCounter {
  min-width: 0;
  text-align: center;
  padding: 0px;
  margin: 0px;
}
fieldset {
  border: 2px dashed transparent;
  border-top-color: #929292;
  color: #929292;
  padding: 0px;
  margin: 0 0 1rem 0;
  min-width: 0; /* prevents from overflowing */
}
legend {
  margin: auto; /* to center */
  padding: 3px 12px;
  background-color: #929292;
  color: white;
}
div.countdown {
  min-width: 0;
  max-width: 100%;
  text-align: center;
}
/* https://stackoverflow.com/q */
img {
  max-width: 350px;
  min-width: 200px;
  -webkit-filter: drop-shadow(2px 2px 5px #9c9c9c);
  filter: drop-shadow(2px 2px 5px #9c9c9c);
  object-fit: cover; /* https://stackoverflow.com/q/11757537 */
}
</style>
