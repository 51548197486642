<!-- Copyright: Seán I. O'Donoghue -->

<!-- eslint-disable vue/no-v-html -->
<template>
  <div id="overlay" ref="overlay" tabindex="0" @click="hitItAndQuit" @keydown.esc="hitItAndQuit">
    <!-- @click.stop="" prevents click on dialog -->
    <div id="modal" ref="modal" :class="{fixedWidth: fixedWidth, frameless: frameless}">
      <!-- @click.stop=""  -->
      <img id="closeButton" src="@/assets/img/close.svg" @click.stop="hitItAndQuit" />
      <slot />
    </div>
  </div>
</template>

<script>
// https://vuejsexamples.com/build-a-custom-modal-component-in-vue-js/
export default {
  name: "DialogModal",
  props: {
    fixedWidth: {type: Boolean, default: true}, // Iscb.vue
    frameless: {type: Boolean, default: false}
  },
  emits: ["hide"],
  mounted() {
    // freeze background - prevent vertical scrolling
    // https://stackoverflow.com/q/56739111
    document.documentElement.style.overflow = "hidden";
    // focus on model div so it can be dismissed with the escape key
    // https://stackoverflow.com/q/49042667
    this.$refs.overlay.focus();
  },
  unmounted() {
    // unfreeze background - re-enable vertical scrolling
    document.documentElement.style.overflow = "auto";
  },
  methods: {
    // eslint-disable-next-line
    log(message) {
      if (process.env.NODE_ENV === "development") {
        //console.log(message); // uncomment to show logs from this component
      }
    },
    hitItAndQuit() {
      this.log(`dialogModal/hitItAndQuit()`);
      this.$emit("hide");
    }
  }
};
</script>

<style scoped>
#overlay {
  position: fixed;
  z-index: 100; /* solves z-issue with Dropdown/vue */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}
#modal {
  position: absolute;
  overflow-y: scroll; /* enable vertical scrolling for large text */
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  z-index: 999;
  max-width: 90vw; /* dynamic-viewport-width: adopts to UX changes */
  max-height: 90dvh; /* dynamic-viewport-height: adopts to UX changes */
  /* no idea why top needs to more 30px */
  padding: 33px 20px 20px 20px; /* top right bottom left */
  margin: 0px;
  border-radius: 20px;
}
.narrow #modal,
.medium #modal {
  width: 75vw;
}
#modal.fixedWidth {
  max-width: 300px;
}
#modal.frameless {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  padding: 0px; /* top right bottom left */
  margin: 0px;
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0.7);
}
#closeButton {
  position: absolute;
  z-index: 999;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
</style>
