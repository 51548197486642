<template>
  <div v-if="$route.name === 'Posters'">
    <h3>Posters</h3>
    <p>Here is where the posterGallery component will go</p>
  </div>
  <router-view v-else v-slot="{ Component }">
    <!-- Or, here is where other poster pages will load (e.g., Upload) -->
    <!-- <keep-alive> is needed here AND in App.vue -->
    <!-- <keep-alive> NOT <keepAlive> -->
    <!-- :key must be on <component>, not on keep-alive -->
    <keep-alive>
      <component :is="Component" :key="$route.fullPath" />
    </keep-alive>
  </router-view>
</template>
