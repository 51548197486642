<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <header v-if="displayWidth === 'narrow'">
    <MainSponsors :key="`${year}-sponsor`" :year="year" />
    <Logo id="logo" />
    <PrimaryNav />
  </header>
  <header v-else>
    <div id="left-column">
      <MainSponsors :key="`${year}-sponsor`" :year="year" />
      <ButtonMail id="mail" />
      <Logo id="logo" />
      <Subtitle />
    </div>
    <!-- <SearchBox /> -->
    <PrimaryNav />
  </header>
</template>

<script setup>
// eslint-disable-next-line
const props = defineProps({
  year: {default: 0, type: [Number, String]}
});
import ButtonMail from "./ButtonMail.vue";
import Logo from "./Logo.vue";
import MainSponsors from "./MainSponsors.vue";
import PrimaryNav from "./Navigation/Primary.vue";
import Subtitle from "./Subtitle.vue";
//import SearchBox from "./SearchBox.vue";
import {inject} from "vue";
const displayWidth = inject("displayWidth"); // from useDisplayMode.js
</script>

<style scoped>
header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 10px;
  box-sizing: border-box;
  height: clamp(165px, 25vw, 190px); /* 170px, 35vw, 190px */
  padding: 0 0.75rem; /* Y, X  0.6rem */
  margin: 0.75rem 0 0.6rem 0; /* Y, X    12px 0px;  */
}
.narrow header {
  height: auto; /* undo height clamp */
  align-items: center;
  column-gap: 5px;
}
#left-column {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between; /* space-between */
  height: 100%;
  box-sizing: border-fox;
}
#mail {
  margin: 0;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  text-align: center;
}
.narrow #logo {
  text-align: center;
  flex: 500; /* expand logo more than others */
}
</style>
