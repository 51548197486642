<template>
  <h3>Terms of service &amp; privacy policies</h3>
  <p id="updated">Last updated: May 4, 2023</p>
  <!-- need to improve / re-write - it's still too much like ISCB -->
  <!-- https://www.iscb.org/iscb-policy-statements/iscb-privacy-policy -->
  <!-- https://www.ebi.ac.uk/about/terms-of-use -->
  <!-- https://www.emblaustralia.org/privacy-policy-2/ -->
  <p>The VIZBI website is run as an independent affiliate activity of the International Society for Computational Biology, Inc. (ISCB), a registered non-profit. The VIZBI organizers are committed to protecting your data. The VIZBI website's terms of use, information collection, usage, sharing, privacy measures, and user options are described in this statement.</p>
  <h4>Personal Information</h4>
  <p>On certain web pages, the VIZBI website collects name and email address information supplied by the user. The VIZBI website also provides links to trusted sites (EMBL and ISCB) that collect more information (including address, telephone number, fax number, credit card numbers) from which the user's identity is discernible.</p>
  <p>These personal data are collected in accordance with GDPR regulations. This means that data is: (a) processed lawfully, fairly and transparently; (b) collected for specific purposes and not used in a way that is incompatible with those purposes; (c) adequate, relevant, and limited to what is necessary for the intended purposes; (d) accurate and kept up-to-date as needed; (e) kept only as long as necessary or with user consent; (f) processed with appropriate security measures in place.</p>
  <h4>Non-Personal Information</h4>
  <p>VIZBI collects non-personal information, including IP addresses and Referring website addresses. In addition, to track overall website statistics, the VIZBI website uses 3rd party cookies from Twitter and Google Analytics. Users can set their browsers to alert them to the use of cookies and may reject the use of the cookie.</p>
  <h4>Sharing of Information</h4>
  <p>VIZBI does not share or sell collected information with third parties, unless legally required or necessary to fulfill the purpose for which you provided your information.</p>
  <h4>How VIZBI Protects your Privacy</h4>
  <p>At VIZBI, we are committed to safeguarding your personal data through reasonable technical and administrative measures. Our team is trained on proper use and confidentiality of information. Payment transactions are redirected to EMBL or ISCB websites, both non-profit organizations that use secure third-party services verified by Authorize.net. We do not store credit card numbers or addresses.</p>
  <h4>Your Options</h4>
  <p>You can unsubscribe from the VIZBI mailing list at any time by clicking the unsubscribe link that is included at the bottom of all emails. Your use of VIZBI website constitutes your acceptance of this Privacy Statement. If you do not agree with all the terms in this Statement, you should not use VIZBI websites or services.</p>
  <h4>Changes to this Terms &amp; Privacy Statement</h4>
  <p>VIZBI reserves the right to make changes to this Statement at any time. These changes are indicated at the top of this page. Your continued use of the VIZBI site indicates your agreement with the latest Privacy Statement.</p>
</template>

<style scoped>
#updated {
  font-style: italic;
}
</style>
