<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <!-- <button v-if="waitingRoomOpen" class="zoom" @click="openLink">Join Waiting Room</button>
  <button v-else-if="live" class="zoom" @click="openLink">Join Meeting</button>
  <router-link v-else-if="person && video" :to="'/Videos/' + video">
    <ButtonBespoke>Open recording</ButtonBespoke>
  </router-link> -->
  <!-- False is just to hide the 'open recording' button for now -->
  <router-link v-if="person && video && false" :to="'/Videos/' + video">
    <ButtonBespoke>Open recording</ButtonBespoke>
  </router-link>
</template>

<script>
import ButtonBespoke from "@/components/ButtonBespoke";
export default {
  name: "VideoLink",
  components: {ButtonBespoke},
  props: {
    waitingRoomOpen: {type: Boolean, default: false},
    live: {type: Boolean, default: false},
    ended: {type: Boolean, default: false},
    eventTitle: {type: String, default: ""},
    person: {type: String, default: ""},
    stream: {type: String, default: ""},
    video: {type: String, default: ""}
  },
  data() {
    return {
      // https://stackoverflow.com/q/14458819
      id: {
        test: btoa("82987216965"),
        lecture: btoa("84471103116"),
        meet: btoa("85748212433")
      },
      params: {
        test: btoa("ZHNTLytsWFl3bWhZZktlK2YwQ3FMdz09"),
        lecture: btoa("YjRBeTA1bHFRS3dKUExZWnQyRUhLQT09"),
        meet: btoa("aHoyaVgzczF1QlYyVWFGVFJXUVhOZz09")
      },
      vimeo: {
        "Seán O'Donoghue": "51dd4f8927",
        "Christian Stolte": "96b8f0a2bb",
        "Ivan Viola": "d922c872c8",
        "Barbora Kozlíková": "e004793e5c"
      }
    };
  },
  methods: {
    openLink() {
      let id = atob(this.id.lecture);
      let param = atob(this.params.lecture);
      if (this.eventTitle.match(/^Meet/i)) {
        id = atob(this.id.meet);
        param = atob(this.params.meet);
      } else if (this.eventTitle.match(/Q&A/i)) {
        id = atob(this.id.test);
        param = atob(this.params.test);
      }
      console.log(`id = ${id}, param = ${param}`);
      let url = `https://kessjones.zoom.us/j/${id}?pwd=${param}`;
      window.open(url, "_blank").focus();
    }
  }
};
</script>

<style scoped>
button.zoom {
  color: white;
  background-color: #3371e5;
}
</style>
