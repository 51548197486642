<!-- check out storybook? > suggested by YouTuber 'LearnVue' -->

<template>
  <!-- frameborder="0" -->
  <iframe ref="frame" :src="iframe_url" width="800" height="230" @load="report_update(this)" />
  <!-- onload="report_update()"  -->
</template>

<script setup>
let iframe_url = "https://vizbi.org/blog";

// eslint-disable-next-line
function log(message) {
  if (process.env.NODE_ENV === "development") {
    console.log(message); // uncomment to show logs in component
  }
}

// https://learnvue.co/articles/template-refs
import {ref} from "vue";
const [frame, new_url] = [ref(""), ref("")];
const report_update = () => {
  log(`report_update() called should have new_url`);
  new_url.value = frame.getAttribute("src");
  log(`new_url = ${new_url}`);
};

import {onMounted} from "vue";
onMounted(() => report_update());

import {watch} from "vue";
watch(new_url, () => {
  // runs when iframe_url changes
  log(`new_url = ${new_url}`);
});
</script>
