// makes a call to the flickr API, retrieving one page of results

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

const flickrRestCall = async function (id) {
  // this function handles the call the flickr
  let url = new URL("https://api.flickr.com/services/rest");
  let params = {
    // https://www.flickr.com/services/api/flickr.photos.getInfo.html
    method: "flickr.photos.getInfo",
    photo_id: id,
    api_key: "1f76f84d4503878c5dbc2b472c469cba", // config.api_key
    format: "json",
    extras: "owner_name",
    nojsoncallback: 1,
  };
  // https://stackoverflow.com/q/37230555
  url.search = new URLSearchParams(params).toString();
  log(`url = ${url}`);
  try {
    const response = await fetch(url);
    if (!response.ok) {
      // https://dmitripavlutin.com/javascript-fetch-async-await/
      throw new Error("Fetch error " + response.status);
    }
    let responseJson = await response.json(); // critical: wait for json
    return responseJson;
  } catch (error) {
    console.error(`Problem accessing flickr API: ${error}`);
  }
};

export async function fetchFlickrInfo(id) {
  // this function handles states within this vue components
  try {
    let response = await flickrRestCall(id);
    if (!response) {
      return console.error(`No response from flickr API.`);
    }
    return await response.photo;
  } catch (error) {
    console.error(`Error fetching flickr id: ${id}`, error);
  }
}

//let test = fetchFlickrInfo("52890725882");
//test

// Code below is a typical flickr response:
let flickrResponse = {
  id: "52891461069",
  secret: "b4af44f33c",
  server: "65535",
  farm: 66,
  dateuploaded: "1683886603",
  isfavorite: 0,
  license: "0",
  safety_level: "0",
  rotation: 0,
  originalsecret: "1ded09021d",
  originalformat: "png",
  owner: {
    nsid: "40300403@N06",
    username: "Seán O'Donoghue",
    realname: "Sean O'Donoghue",
    location: "",
    iconserver: "0",
    iconfarm: 0,
    path_alias: null,
    gift: { gift_eligible: true, eligible_durations: ["year", "month", "week"], new_flow: true },
  },
  title: { _content: "embl-1" },
  description: { _content: "" },
  visibility: { ispublic: 1, isfriend: 0, isfamily: 0 },
  dates: { posted: "1683886603", taken: "2023-05-12 03:16:11", takengranularity: 0, takenunknown: "1", lastupdate: "1683886612" },
  views: "0",
  editability: { cancomment: 0, canaddmeta: 0 },
  publiceditability: { cancomment: 1, canaddmeta: 0 },
  usage: { candownload: 1, canblog: 0, canprint: 0, canshare: 1 },
  comments: { _content: "0" },
  notes: { note: [] },
  people: { haspeople: 0 },
  tags: { tag: [{ id: "40255081-52891461069-53217631", author: "40300403@N06", authorname: "Seán O'Donoghue", raw: "VIZBI", _content: "vizbi", machine_tag: 0 }] },
  urls: { url: [{ type: "photopage", _content: "https://www.flickr.com/photos/40300403@N06/52891461069/" }] },
  media: "photo",
};
flickrResponse.photos = {};
