import {ref} from "vue";
const showFocusOutline = ref(false);

// Event handler for focus event
function onFocus(event) {
  // console.log(`focusHandlers: say something`);
  // event.target.style.outline = "3px dotted var(--brand-color) !important";
  // event.target.style.outline = "3px dotted #3c9 !important";
  event.target.classList.add("showFocusOutline");
  // let link = event.target;
  // console.log(`focusHandlers: link = ${link.textContent}`);
  // console.log(`focusHandlers: classes: ${Array.from(link.classList)}`);
}

// Event handler for blur event
function onBlur(event) {
  // event.target.style.outline = "none";
  event.target.classList.remove("showFocusOutline");
}

function toggleFocusListeners() {
  // below selects only elements with a non-negative tabindex attribute
  const links = document.querySelectorAll("a, button");
  // const links = document.querySelectorAll("a[tabindex], button[tabindex]");
  // const links = document.querySelectorAll("a[tabindex], button[tabindex]");
  // const links = document.querySelectorAll(":scope a[tabindex], :scope button[tabindex]");
  links.forEach((link) => {
    // // console.log(`focusHandlers: About to modify event listener for ${link.textContent}`);
    if (showFocusOutline.value) {
      // console.log(`focusHandlers: Adding event listener for ${link.textContent}`);
      link.addEventListener("focus", onFocus);
      link.addEventListener("blur", onBlur);
      return;
    }
    // console.log(`focusHandlers: Removing event listener for ${link.textContent}`);
    link.removeEventListener("focus", onFocus);
    link.removeEventListener("blur", onBlur);
  });
}

function handleGlobalKeyPress(event) {
  if (showFocusOutline.value) return;
  const key = event.key || event.keyCode;
  if (key === "Tab" || (key === "Shift" && event.shiftKey) || key === "Enter") {
    showFocusOutline.value = true;
    toggleFocusListeners();
    // console.log("focusHandlers: add listeners to all <a>");
  }
}

function handleGlobalClick() {
  if (!showFocusOutline.value) return;
  showFocusOutline.value = false;
  toggleFocusListeners();
  // console.log("focusHandlers: remove all listeners");
}

export default function useFocusHandlers() {
  document.addEventListener("click", handleGlobalClick);
  document.addEventListener("touchstart", handleGlobalClick);
  document.addEventListener("keydown", handleGlobalKeyPress);
}
