<template>
  <h3>VIZBI admin</h3>
  <SignIn :dialog="login_required" />
  <Transition appear>
    <section v-if="visible">Option A</section>
    <section v-else>Option B</section>
  </Transition>
</template>

<script setup>
import SignIn from "/src/components/SignIn";
import { ref } from "vue";
const login_required = ref(false);
</script>

<style scoped>
section {
  background-color: gainsboro;
  border: 1px solid #bdbdbd;
  margin-bottom: 16px;
}

.v-enter-from {
  opacity: 0;
}

.v-enter-to {
  opacity: 1;
}

.v-enter-active {
  transition: all 1.5s ease;
}

.v-leave-from {
  opacity: 1;
}

.v-leave-to {
  opacity: 0;
}

.v-leave-active {
  transition: all 0.5s ease;
}
</style>
