<!-- Copyright: Neblina Sikta, Sean I. O'Donoghue -->

<template>
  <!-- https://vuejs.org/api/built-in-directives.html#v-cloak -->
  <h4 v-show="selectedPeople.length > 0">{{ name }}</h4>
  <div class="gallery">
    <Person
      v-for="person in selectedPeople"
      v-cloak
      :key="person + 'fig'"
      :name="person"
      :affiliation="people[person].Affiliation"
      :category="category"
      :categories="people[person].Category"
      :date="people[person].Date"
      :day="day"
      :highlights="highlights"
      :topic="people[person].Topic"
      :url="people[person].URL"
      :tags="tags"
      @updateHighlightedPerson="updateHighlightedPerson"
      @updateParentCategory="updateParentCategory"
      @updateParentDay="updateParentDay" />
    <!-- Add empty figures to make fill the screen width: 3, 4, or 5 -->
    <figure v-for="ghost in ghosts" :key="ghost" :class="[ghost]" />
  </div>
  <!-- v-if="highlights"  -->
  <PersonHighlight
    :key="highlightedPerson"
    :person="highlightedPerson"
    :people="people"
    :selected-people="selectedPeople"
    :category="category"
    :day="day"
    @hide="highlightedPerson = ''" />
</template>

<script>
import moment from "moment";
import Person from "@/components/Person";
import PersonHighlight from "@/components/PersonHighlight";
import stringify from "json-stringify-safe";
export default {
  name: "PeopleGallery",
  components: {Person, PersonHighlight},
  props: {
    category: {type: String, default: ""}, // if defined, show this only
    day: {type: String, default: ""},
    highlights: {type: Boolean, default: false},
    name: {type: String, default: ""},
    tags: {type: Boolean, default: true},
    sort: {type: String, default: ""}
  },
  emits: ["foundCategories", "foundDays", "updateCategory", "updateDay"],
  data() {
    return {
      //fetchHeadshots: [],
      //headshot: {},
      count: 0, // how many people in gallery
      ghosts: [], // list of 'ghost' people needed to fill one row of the grid
      highlightedPerson: "",
      people: {}, // processed version of .json file data
      peopleArray: [], // initial data read from .json file
      selectedPeople: []
      //showGallery: false // hide until initial render is finished
    };
  },
  computed: {
    groupIsNotEmpty() {
      return this.selectedPeople.length > 0;
    }
  },
  watch: {
    category() {
      this.updateSelectedPeople(); // update selection with category changes
    },
    day() {
      this.updateSelectedPeople(); // update selection with day changes
    },
    sort() {
      this.updateSelectedPeople(); // update selection with day changes
    }
  },
  async created() {
    this.peopleArray = await this.loadJSONfiles();
    this.people = this.arrayToObject(this.peopleArray);
    // send extracted categories to parent
    this.$emit("foundCategories", this.getCategories(this.people));
    // send extracted days to parent
    this.$emit("foundDays", this.getDates(this.people));
    // https://stackoverflow.com/q/43652265
    this.updateSelectedPeople();
    this.log(`created() PeopleGallery(${this.name})`);
    this.log(`selectedPeople = ${this.selectedPeople.length} ('${this.category}', '${this.day}', '${this.sort}')`);
  },
  methods: {
    log(message) {
      // eslint-disable-line
      if (process.env.NODE_ENV === "development") {
        //console.log(message); // uncomment to show logs from this component
      }
    },
    async loadJSONfiles() {
      try {
        let file = this.name.toLowerCase().replace(/ /, "-");
        let year = this.$route.params.year;
        return await require(`@/assets/years/${year}/People/${file}.json`);
      } catch (error) {
        console.error(`/People: error fetching json: ${error}`);
      }
    },
    updateSelectedPeople() {
      this.log(`updateSelectedPeople() called`);
      if (!this.people || Object.keys(this.people).length === 0) {
        return []; // early exit when called before mount
      }
      let people = this.people;
      let selectedPeople = Object.keys(this.people);
      //let fetchHeadshots = [];
      if (this.category) {
        // when a category is set, return the filtered list of people
        let category = this.category;
        selectedPeople = selectedPeople.filter(
          (person) => people[person]["Category"].includes(category)
          // .includes() allow a person to have multiple categories
        );
      } else if (this.day) {
        // when a day is set, return the filtered list of people
        let day = this.day;
        selectedPeople = selectedPeople.filter(
          (person) => people[person]["Date"] && people[person]["Date"].includes(day)
          // .includes() allow a person to have multiple days
        );
      } else {
        this.log(`No selected session or day`);
      }
      this.log(`selectedPeople = ${stringify(selectedPeople)}`);
      if (this.sort === "First name") {
        // default JS sorting
        selectedPeople = selectedPeople.sort();
      } else if (this.sort === "Last name") {
        selectedPeople = this.sortLastName(selectedPeople);
      } else {
        // by default
        selectedPeople = this.sortCategory(selectedPeople);
      }
      if (selectedPeople.length < 5) {
        // fill one grid row with empty <figure> tags by adding 'ghosts'
        let ghosts = 5 - selectedPeople.length; // number of ghosts needed
        this.ghosts = []; // remove any existing 'ghosts'
        for (let i = 1; i <= ghosts; i++) {
          this.ghosts.push(`ghost-${i}`);
        }
      }
      this.selectedPeople = selectedPeople;
    },
    arrayToObject(peopleArray) {
      if (!peopleArray || Object.keys(peopleArray).length === 0) {
        return {}; // early exit when called before mount
      }
      let people = {}; // the main output
      let properties = peopleArray[0]; // read properties from first row
      properties = properties.map((property) => property.replace(/^Talk.*/i, "Topic"));
      this.log(`properties = ${stringify(properties)}`);
      let rowIndex;
      // skip first row containing headers
      for (rowIndex = 1; rowIndex < peopleArray.length; rowIndex++) {
        let rowData = peopleArray[rowIndex];
        let columnIndex; //this is column count
        let name = "";
        let person = {};
        for (columnIndex = 0; columnIndex < properties.length; columnIndex++) {
          if (!rowData[columnIndex]) {
            this.log("warning: no data");
            continue;
          } else if (properties[columnIndex].match(/name/i)) {
            this.log(`Found a person's name: ${rowData[columnIndex]}`);
            name = rowData[columnIndex];
          } else if (properties[columnIndex].match(/date/i)) {
            person[properties[columnIndex]] = this.formatDay(rowData[columnIndex]);
          } else if (properties[columnIndex] === "Description") {
            if (rowData[columnIndex]) {
              // if there is a description for this speaker's talk
              // store it in a new object, with session name as key
              person["Description"] = {};
              person["Description"][person["Category"]] = rowData[columnIndex];
            }
          } else {
            person[properties[columnIndex]] = rowData[columnIndex];
          }
        }
        person.Category = this.regularizeCategoryNames(person);
        if (people[name]) {
          // if this person appears twice in this group, merge categories
          if (person["Category"] !== people[name]["Category"]) {
            // only merge if the categories are different
            person["Category"] = people[name]["Category"] + "; " + person["Category"];
          }
          // also merge dates
          if (person["Date"] !== people[name]["Date"]) {
            // but only if the dates are different
            person["Date"] = people[name]["Date"] + "; " + person["Date"];
          }
          // also merge titles
          person["Topic"] = people[name]["Topic"] + "; " + person["Topic"];
          if (people[name]["Description"]) {
            // if the previous array item for this person had a descriptions
            // store with current description in new object
            person["Description"] = {
              ...people[name]["Description"],
              ...person["Description"]
            };
          }
        }
        if (!name.match(/\?/) && !name.match(/^T.?B.?A$/i)) {
          // Exclude names with questions marks or 'T.B.A.'
          people[name] = person;
        }
      }
      if (Object.keys(people).length > 0) {
        return people;
      } else {
        return null;
      }
    },
    regularizeCategoryNames(person) {
      // Rename categories to shorten name
      if (person.Category === "Cellular Systems") {
        return "Cells";
      } else if (person.Category === "Tissues & Organisms") {
        return "Tissues";
      } else if (person.Category === "Populations & Ecosystems") {
        return "Ecosystems";
      } else {
        return person.Category;
      }
    },
    updateHighlightedPerson(highlightedPerson) {
      this.highlightedPerson = highlightedPerson;
    },
    updateParentCategory(category) {
      this.log(`updateParentCategory(${category})`);
      this.$emit("updateCategory", category); // send selected session to parent
    },
    updateParentDay(day) {
      this.$emit("updateDay", day); // send selected day up to parent
    },
    formatDate(date) {
      // https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
      return moment(date, "DD/MM/YYYY").format("ddd MMM DD");
    },
    formatDay(date) {
      // https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
      return moment(date, "DD/MM/YYYY").format("ddd");
    },
    sortCategory(selectedPeople) {
      this.log(`sortCategory()`);
      let people = this.people;
      let sortedSpeakers = selectedPeople.sort((a, b) => {
        this.log(`[a, b] = [${a}, ${b}]`);
        let aIsKeynote = people[a].Category.includes("Keynote") || false;
        let bIsKeynote = people[b].Category.includes("Keynote") || false;
        let aIsShowcase = people[a].Category.includes("Showcase") || false;
        let bIsShowcase = people[b].Category.includes("Showcase") || false;
        if (aIsKeynote && !bIsKeynote) {
          return -1; // keep 'a' before 'b'
        } else if (!aIsKeynote && bIsKeynote) {
          return 1; // swap order
        } else if (aIsShowcase && !bIsShowcase && !bIsKeynote) {
          return -1; // keep 'a' before 'b'
        } else if (aIsShowcase && !bIsShowcase && bIsKeynote) {
          return 1; // swap order
        } else if (!aIsShowcase && !aIsKeynote && bIsShowcase) {
          return 1; // swap order
        } else {
          return 0; // keep order
        }
      });
      // now sort Masterclass last
      sortedSpeakers = sortedSpeakers.sort((a, b) => {
        let aIsMasterclass = people[a].Category.includes("Masterclass") || false;
        let bIsMasterclass = people[b].Category.includes("Masterclass") || false;
        if (aIsMasterclass && !bIsMasterclass) {
          return 1; // swap order
        } else if (!aIsMasterclass && bIsMasterclass) {
          return -1; // keep 'a' before 'b'
        } else {
          return 0; // keep order
        }
      });
      return sortedSpeakers;
    },
    sortLastName(selectedPeople) {
      this.log(`sortLastName()`);
      let sortedSpeakers = selectedPeople.sort((a, b) => {
        let aLastName = a.replace(/^.* /, "");
        let bLastName = b.replace(/^.* /, "");
        // https://stackoverflow.com/q/6712034
        return aLastName.localeCompare(bLastName);
      });
      return sortedSpeakers;
    },
    toggleDate(date) {
      this.$parent.toggleDate(date);
    },
    toggleSession(session) {
      this.$parent.toggleSession(session);
    },
    toggleOrganizers(category, event) {
      this.$parent.toggleOrganizers(category, event);
    },
    selectTopic(person) {
      if (!this.people[person].Category) {
        console.error(`Shouldn't happen: no category for ${person}`);
        return;
      }
      let categories = this.people[person].Category.split(/\s*;\s*/);
      let thisCategoryIndex = 0; //default value when no category is set
      if (this.category) {
        // if a category is selected, is it first or second for this person?
        thisCategoryIndex = categories.indexOf(this.category);
        if (thisCategoryIndex === -1) {
          console.error(`Shouldn't happen: ${person} lacks ${this.category}`);
          return;
        }
      }
      let topics = this.people[person].Topic.split(/\s*;\s*/);
      this.log(`topics = ${stringify(topics)}`);
      return topics[thisCategoryIndex];
    },
    getDates(people) {
      this.log(`getDates called`);
      let dateKeys = {}; // initially, store dates as keys in object
      Object.keys(people).forEach(function (person) {
        if (people[person]["Date"]) {
          let date = people[person]["Date"];
          // handle case when string has two dates separated by ';'
          date.split(/\s*;\s*/).forEach(
            // set day as a key in category object
            (day) => (dateKeys[day] = 1)
          );
        }
      });
      this.log(`dates = ${Object.keys(dateKeys)}`);
      return Object.keys(dateKeys);
    },
    getCategories(people) {
      this.log(`getCategories called`);
      let categoryKeys = {}; // initially, store categories as keys in object
      // merge objects - https://stackoverflow.com/q/171251
      Object.keys(people).forEach((person) => {
        if (!people[person]["Category"]) {
          this.log(`no category for ${person}`);
          // skip this person - don't show them on the page
          return; // escape for loop
        }
        let category = people[person]["Category"];
        // handle case when string has two categories separated by ';'
        this.log({category});
        category.split(/\s*;\s*/).forEach(
          // set category as a key in category object
          (session) => (categoryKeys[session] = 1)
        );
      });
      this.log(`categories = ${Object.keys(categoryKeys)}`);
      return Object.keys(categoryKeys);
    },
    getHeadshotBlank() {
      this.log(`getHeadshotBlank()`);
      // show a blank image while waiting for getHeadshots to load
      let string =
        "data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAKcAAADxCAIAAAAhozBRAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3RQREAIAzAMMC/gblFxh5NFPSud2YOMW87gA";
      for (let i = 1; i <= 59; i++) {
        string += "WuF7le5HqR60"; // add 59 copies
      }
      string += "WuF7le5HrRB1r6A60kT/oiAAAAAElFTkSuQmCC";
      return string;
    }
  }
};
</script>

<style scoped>
/* swtichted to grid layout - will never look back! */
/* https://www.youtube.com/watch?app=desktop&v=TUD1AWZVgQ8&t=876s */
.gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)); /* Mobile 1st */
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
  grid-row-gap: 0.4rem;
  grid-column-gap: 0.2rem;
  margin: 0px;
  padding: 0px;
}
.medium .gallery {
  grid-template-columns: repeat(auto-fit, minmax(26%, 1fr));
  grid-row-gap: 0.45rem;
  grid-column-gap: 0.3rem;
}
.wide .gallery {
  grid-template-columns: repeat(auto-fit, minmax(22%, 1fr));
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.4rem;
}
.unlimited .gallery {
  grid-template-columns: repeat(auto-fit, minmax(18%, 1fr)); /* 15%=6 columns */
  grid-row-gap: 0.55rem;
  grid-column-gap: 0.5rem;
}
/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/figure */
/* https://www.w3schools.com/howto/howto_css_image_grid_responsive.asp */
/* medium */
</style>
