<template>
  <h3>Sponsors</h3>
  <!-- 'div' is needed for :deep selector to work -->
  <div>
    <!-- v-if prevents errors when switching routes -->
    <component :is="page" v-if="$route.name" />
  </div>
</template>

<script setup>
import {useRoute} from "vue-router";
const route = useRoute();
import {shallowRef} from "vue";
let page = shallowRef();
try {
  if (route.params.year) {
    let year = route.params.year;
    page = shallowRef(require(`@/assets/years/${year}/Sponsors/Index.vue`).default);
  }
} catch (error) {
  console.error(`Sponsors.vue|${route.params.year} error: ${error}`);
}
</script>

<style scoped>
/* Deep selector = styles are applied to ~year/Sponsors/Index.vue */
div :deep(h4) {
  font-size: 1.5rem;
  margin-top: 2.4rem;
}
div :deep(div) {
  display: flex; /* rows by default */
  flex-wrap: wrap; /* allow rows to wrap */
  align-items: center; /* baseline */
  gap: 1.9rem; /* horizontal & vertical space between images */
  margin-top: 1.1rem;
}
.narrow div :deep(div) {
  gap: 1.6rem; /* horizontal & vertical space between images */
}
div :deep(img) {
  max-height: 3.3rem;
}
.narrow div :deep(img) {
  max-height: 2.6rem;
}
/* How NOT to use the deep selector:
body >>> div {
body /deep/ div {
:deep(.sponsorLogos) {
div :deep(.sponsorLogos) {
:deep(>div) {
*/
</style>
