<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div id="main_content">
    <div id="left_column">
      <!-- Component sent from parent will appear here -->
      <slot />
      <!-- https://stackoverflow.com/q/42992579 -->
    </div>
    <div id="right_column">
      <div v-if="$route.params.year && $route.params.year === '2023'">
        <img v-if="displayMode === 'lightMode'" id="sponsor" src="@/assets/years/2023/Sponsors/img/embo-primary-black.svg" alt="EMBO" title="EMBO" />
        <img v-else id="sponsor" src="@/assets/years/2023/Sponsors/img/embo-primary-white.svg" alt="EMBO" title="EMBO" />
      </div>
      <TwitterFeed :key="rerenderTweets" />
    </div>
  </div>
</template>

<script setup>
const log = (message) => console.log(); // eslint-disable-line
import TwitterFeed from "@/components/TwitterFeed.vue";
import {inject, onActivated, ref, watch} from "vue";
const rerenderTweets = ref(0);
const displayMode = inject("displayMode");

onActivated(() => {
  log("TwitterFeed activated()");
  // https://v3.vuejs.org/api/options-lifecycle-hooks.html#activated
  // Called when a kept-alive component is activated.
  rerenderTweets.value += 1;
  // Changing :key will force a re-rendering of TwitterFeed component
  // https://michaelnthiessen.com/force-re-render/
});
watch(displayMode, () => (rerenderTweets.value += 1));
</script>

<style scoped>
/* scoped = CSS limited to this component only */
#main_content {
  display: inline-flex;
  /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
  justify-content: space-between;
  /* Distribute evenly; 1st item flush with start; last flush with end */
  align-items: top;
  overflow: hidden; /* https://stackoverflow.com/q/2062258 */
}
#main_content #left_column {
  /* width: 75%; */
  flex-grow: 0; /* don't grow */
  flex-shrink: 40; /* allow to shrink */
  flex-basis: 670px; /* Size of item before growing and shrinking happens */
  margin: 0px;
  padding: 0px; /* 8px 0px 8px; */
}
#main_content #right_column {
  width: 200px;
  flex-grow: 0; /* don't grow */
  flex-shrink: 0; /* don't shrink */
  flex-basis: 230px; /* Size of item before growing and shrinking happens */
  margin-left: 12px;
}
#main_content #left_column,
#main_content #right_column {
  padding: 0px;
  /* padding: 0px 8px 0px 8px; */
}
img#sponsor {
  /* Only needed for EMBO 2023 */
  vertical-align: middle;
  display: block;
  width: 55px;
  margin-top: 5px;
  margin-left: 0px;
  margin-bottom: 20px;
  padding: 0px;
  width: 70%;
  height: auto;
}
#main_content a {
  display: none;
} /* hide tweet button on large screens */
/*@media only screen and (max-width: 640px) { */
/* small screen breakpoint */
.medium #main_content,
.narrow #main_content {
  /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
  display: block;
}
.medium #main_content a,
.narrow #main_content a {
  /* reveal tweet button */
  display: auto;
  float: right;
  background-color: #04aa6d;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
}
.medium #main_content #right_column,
.narrow #main_content #right_column {
  margin-left: 0px;
  /* display: none; */
}
</style>
