<template>
  <span />
</template>

<style scoped>
span {
  flex: 1; /* allow to shrink */
  display: inline-block;
  border-right: 0.1em solid currentColor;
  border-top: 0.1em solid currentColor;
  width: 0.4em;
  height: 0.4em;
  margin-top: 0.4em;
  margin-left: 0.25em;
  margin-right: 0.4em;
  margin-bottom: 0.1em;
  transform: rotate(45deg); /* produces a right arrow */
  -webkit-transform: rotate(45deg);
}
/* classes below over-write above transform statement */
.left {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
/* translation added to compensates for shifted origin */
.up {
  transform: translate(0em, 0.2em) rotate(-45deg);
  -webkit-transform: translate(0em, 0em) rotate(-45deg);
}

.up-old {
  transform: translate(0em, 0.4em) rotate(-45deg);
  -webkit-transform: translate(0em, 0.4em) rotate(-45deg);
}
.down {
  transform: translate(0em, -0.05em) rotate(135deg);
  -webkit-transform: translate(0em, -0.05em) rotate(135deg);
}
</style>
