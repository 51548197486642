<template>
  <div>
    <a href="https://dornsife.usc.edu/bridge-institute/" target="_blank">
      <img class="bridge" src="./img/Bridge-Institute-background.png" alt="USC Bridge Institute" title="USC Bridge Institute" />
    </a>
    <a href="https://www.frontiersin.org/journals/bioinformatics" rel="nofollow">
      <img src="./img/Frontiers.png" alt="FBINF" title="Frontiers in Bioinformatics" class="frontiers" />
    </a>
  </div>
  <h4>Affiliations</h4>
  <div>
    <a href="http://www.iscb.org/" rel="nofollow">
      <img src="./img/ISCB.jpeg" alt="ISCB" title="ISCB" />
    </a>
  </div>
  <h4>Media Partners</h4>
  <div>
    <a href="https://peerj.com/">
      <img src="./img/PeerJ.png" alt="PeerJ" title="PeerJ" />
    </a>
  </div>
</template>

<style scoped>
img.FBINF {
  max-height: 30px;
  max-width: 100%;
}

img.bridge {
  max-height: 40px;
  max-width: none;
}
</style>
