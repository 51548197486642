// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

import { nextTick } from "vue";

export function keepScrollPosition() {
  // Capture scroll position of body
  // https://stackoverflow.com/q/58076602
  const element = document.getElementsByTagName("body");
  log(`currentScrollPosition: element = ${element}`);
  let scrollPosition = element.scrollTop;
  // In a nextTick, restore the element's scrollTop
  nextTick(() => {
    log(`scrollPosition = ${scrollPosition}`);
    element.scrollTop = scrollPosition;
  });
}
