export default {
  install(app, {pixelId}) {
    if (process.env.NODE_ENV !== "production") {
      const fbqNoop = () => {}; // Define a no-operation function
      app.config.globalProperties.$fbq = fbqNoop;
      return; // this disables the pixel for development servers
    }
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
    window.fbq("init", pixelId);
    window.fbq("track", "PageView");
    app.config.globalProperties.$fbq = window.fbq; // make globally available
  }
};

// Then on Swoogo do the following steps:
// (1) Website > Tools and analytics > Facebook Pixel > enter meta-pixel ID
// (2) Paste code below at ISCB events > Account Setup > JavaScript Snippets
// (3) Website > Tools and analytics > JavaScript Snippets > Head of page > onfirmation page
//
/* <script>
setTimeout(function() {
    if (typeof fbq === 'function') {
        fbq('track', 'CompleteRegistration');
        fbq('track', 'Purchase', {value: '400', currency: 'USD'});
    } else {
        console.log('Meta Pixel not initialized.');
    }
}, 2000); // Delay as needed to allow Swoogo to initialize facebook code
</script> */
