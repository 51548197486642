<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div v-if="host !== 'vizbi.org'">
    <!-- 344 worked in isolation -->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 25">
      <text x="0" y="50%" dominant-baseline="middle" text-anchor="start" fill="currentColor">
        Helping Life Scientists use DataVis, Design, and UX
      </text>
      <!--Helping Life Scientists use DataVis, Design, and Human-Computer Interaction-->
    </svg>
  </div>
</template>

<script>
let host = window.location.host; //eslint-disable-line
</script>

<style scoped>
svg:not(:root) {
  width: 100%;
  /* height: 100%;
  height: auto; */
  color: var(--title-color-subtle);
  opacity: 80%; /* 70% */
  background-color: red;
}
.darkMode svg {
  color: var(--title-color);
  opacity: 50%;
}
.unlimited svg {
  opacity: 50%; /* 70% */
}
.wide svg {
  opacity: 60%; /* 70% */
}

.medium svg {
  opacity: 70%; /* 70% */
}
.narrow svg {
  display: block;
  font-size: clamp(33px, 6.9vw, 30px); /* min, preferred, max was: 3.12em; */
  line-height: clamp(29.7px, 6.3vw, 33px); /* was 0.9em; */
}
</style>
