// Returns reactive variable tracking system switch between light and dark mode.

const log = (message) => console.log(); // eslint-disable-line
// set current displayMode as a body class
import {ref} from "vue";
const displayMode = ref("");
function changeDisplayModeClass(newClass) {
  if (!newClass) return displayMode.value;
  if (displayMode.value === newClass) return displayMode.value;
  if (displayMode.value) document.body.classList.remove(displayMode.value);
  document.body.classList.add(newClass);
  return newClass;
}
// calculate current displayMode
const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
const getMediaPreference = function () {
  // currently, only enable dark mode on localhost
  // if (location.host.match(/^vizbi/i)) return "lightMode";
  // next line runs the query https://stackoverflow.com/q/58626873
  if (mediaQuery.matches) {
    return changeDisplayModeClass("darkMode");
  } else {
    return changeDisplayModeClass("lightMode");
  }
};
// export to app
import {onMounted} from "vue";
export function useDisplayMode() {
  // get initial value:
  onMounted(() => {
    displayMode.value = getMediaPreference();
    log(`displayMode initial value: ${displayMode.value}`);
    // listen for updates:
    mediaQuery.addEventListener("change", () => {
      displayMode.value = getMediaPreference();
      log(`displayMode is now ${displayMode.value}`);
    });
  });
  return displayMode;
}
