// Copyright: Sean O'Donoghue
// Test the useRoute function

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    console.log(message); // uncomment to show logs in component
  }
};

import { useRoute } from "vue-router";
const route = useRoute();
import { ref } from "vue";
const year = ref("");
import { watch } from "vue";
watch(route, () => {
  log(`route has changed; fetching program again`);
  if (!route) {
    log(`route is undefined`);
    return;
  }
  if (!route.params) {
    log(`route.params undefined`);
    return;
  }
  if (!route.params.year) {
    log(`route.params.year undefined`);
    return;
  }
  year.value = route.params.year;
});

export function useRouteTest() {
  return year;
}
