// Returns currently selected year

const log = (message) => console.log(); // eslint-disable-line

import {ref} from "vue";
// const selectedYear = ref("");

import {useRoute} from "vue-router";
import {watch} from "vue";

export function useYear() {
  // function to calculate selected year
  const selectedYear = ref("");
  const route = useRoute();
  function updateSelectedYear() {
    if (!route.name || route.name !== "Year") return "";
    if (!route.params || !route.params.year) return "";
    return route.params.year;
  }
  log(`route.params.year: ${route.params.year}`);
  // calculate initial value
  selectedYear.value = updateSelectedYear();
  log(`selectedYear: initial value = ${selectedYear.value}`);
  // when route changes, check if selectedYear is updated
  watch(
    route,
    () => {
      selectedYear.value = updateSelectedYear();
      log(`selectedYear: ${selectedYear.value}`);
    },
    {immediate: true, deep: true}
  );
  return selectedYear;
}
