let array1 = [1, 2, 3, 2];
let array2 = [6, 3, 4, 56, 234];
array1;
array1.concat(array2); //?
array1; //

const person = {
  name: "Seán O'Donoghue",
  age: 57,
  emoji: "👍", // control + command + space
};
person;

let date = new Date();
date;

console.log(array1);

if (false) {
  console.log("something unreachable");
}

console.log("hi");

// benchmarking / timing info (pro only)
array1.forEach((n) => {
  n; //?.
});
