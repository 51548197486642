<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div id="loadingMessage">
    <span v-if="scrolledToBottom">Loading...</span>
    <!-- Loading... -->
  </div>
</template>

<script setup>
// https://renatello.com/check-if-a-user-has-scrolled-to-the-bottom-in-vue-js/
import stringify from "json-stringify-safe";

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};
// eslint-disable-next-line
const emit = defineEmits(["fetchNextPage"]);
import {ref} from "vue";
const scrolledToBottom = ref(false);
const scrollPosition = function () {
  let bottomOfWindow = false;
  let scrollPosition = Math.round(window.innerHeight + window.scrollY);
  if (scrollPosition + 5 >= document.body.offsetHeight) {
    log("you're at the bottom of the page");
    bottomOfWindow = true;
  }
  log(`[scrollPosition, offsetHeight, bottomOfWindow] = ${[scrollPosition, document.body.offsetHeight, bottomOfWindow]}`);
  if (bottomOfWindow) {
    scrolledToBottom.value = true;
    // https://stackoverflow.com/q/58076602
    // const element = document.getElementById("loadingMessage");
    // capture the scrollTop
    //let scrollPosition = element.scrollTop;
    // Update the list of images
    emit("fetchNextPage");
  }
};

import {onMounted} from "vue";
onMounted(() => {
  scrollPosition(); // run initially
  const element = document.getElementById("loadingMessage");
  log(`element = ${stringify(element)}`);
  //element.onscroll = function() {
  //scrollPosition(); // run upon scroll
  //};
  window.addEventListener("scroll", () => scrollPosition());
});
import {onUnmounted} from "vue";
onUnmounted(() => {
  window.removeEventListener("scroll", () => scrollPosition());
});
</script>

<style scoped>
#loadingMessage {
  text-align: center;
}
</style>
