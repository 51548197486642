// Copyright: Sean O'Donoghue
// Test the useRoute function
// https://stackoverflow.com/q/66683113

import { useRoute } from "vue-router";
import { computed, ref } from "vue";

export function useRequire() {
  const route = useRoute();
  const usePath = computed(() => {
    console.log(`step 1`);
    if (!route) {
      console.log(`step 2`);
      return;
    }
    if (!route.path) {
      console.log(`step 3`);
      return;
    }
    console.log(`step 4`);
    let year = route.path.slice(0, 5);
    return `@/assets/years${year}/img/Hero/Medium.jpg`;
    //return route.path;
  });
  console.log(`usePath.value = ${usePath.value}`);
  //https://stackoverflow.com/q/66411122/
  //const usePicture = ref(require(usePath.value));
  //const usePicture = ref(require("@/assets/years/2023/img/Hero/Medium.jpg"));
  //import usePicture from eval(usePath.value);
  const usePicture = ref();
  return { usePath, usePicture };
}
