<!-- Copyright: Seán I. O'Donoghue -->
<template>
  <ArchiveGrid v-if="displayWidth === 'narrow'" :years="years" :selected="selectedYear" />
  <ArchiveDropdown v-else :years="years" :selected="selectedYear" />
</template>

<script setup>
const log = (message) => console.log(); // eslint-disable-line
import ArchiveDropdown from "@/components/Navigation/Archive/Dropdown.vue";
import ArchiveGrid from "@/components/Navigation/Archive/Grid.vue";
// set up years to show
import history from "@/assets/history.json";
let years = Object.keys(history.years).reverse();
years = years.filter((year) => year < history.latestYear);
// set up displayWidth
import {inject} from "vue";
const displayWidth = inject("displayWidth"); // from App.vue > composable/
// check if a given year is selected
const selectedYear = inject("selectedYear"); // from App.vue > composable/
log(`selectedYear = ${selectedYear.value}`);
</script>

<style scoped></style>
