<template>
  <h3>Showcase</h3>
  <!-- v-if prevents errors when switching routes -->
  <component :is="page" v-if="$route.name" />
  <PeopleGallery :highlights="true" name="Speakers" category="Showcase" />
  <!-- @updateDay="selectDay" @updateCategory="selectCategory" -->
  <h4 id="program">Preliminary program</h4>
  <ProgramTable session="Showcase" :description="true" />
</template>

<script setup>
import PeopleGallery from "@/components/People/Gallery.vue";
import ProgramTable from "@/components/Program/Table.vue";
import {useRoute} from "vue-router";
const route = useRoute();
import {shallowRef} from "vue";
let page = shallowRef();
try {
  if (route.params.year) {
    let year = route.params.year;
    page = shallowRef(require(`@/assets/years/${year}/Showcase/Index.vue`).default);
  }
} catch (error) {
  console.error(`Showcase.vue|${route.params.year} error: ${error}`);
}
</script>

<style scoped>
h3 {
  display: inline; /* inline-block */
  line-height: 16px;
  margin-top: 22px; /* float compenstation */
}
h4 {
  float: left;
  clear: left; /* https://stackoverflow.com/q/3415635 */
}
.narrow h4,
.narrow h4 {
  margin-bottom: 8px; /* same as App.vue/h3 */
}
table#programTable {
  margin-top: 1.8em;
}
</style>
