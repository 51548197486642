<template>
  <h3>Page to test year-based views</h3>
  {{ usePath }}
  <div>
    <img :src="picture" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRequire } from "@/composables/tests/useRequire";
const { usePath } = useRequire();

const picture = ref(require("@/assets/years/2023/img/Hero/Medium.jpg"));

//import { useProgram } from "@/composables/useProgram";
//const { program } = useProgram();
</script>
