<template>
  <p>VIZBI 2021 will be a fully virtual conference. We will use the software platforms listed below to facilitate virtual engagement, communication, and networking for attendees of this event.</p>
  <p><a href="https://slack.com/">Slack</a> will be available for use by all of the attendees of this event to collaborate, communicate and network. All participants will receive information on how to join shortly before the conference. Please download and read the <a href="VIZBI_Slack_guide.pdf">VIZBI Slack Guide</a>.</p>
  <p><a href="https://zoom.us/download">Zoom</a> will be used for all live streamed talks. In addition, Zoom will be used for several other aspects of the programme, such as speed networking and breakout sessions. To ensure you are able to participate in these sessions please use the latest version of the Zoom client available for your device rather than the web based app.</p>
  <p>EMBL Media Site is an alternative video streaming service that will be used to broadcast live talks, as well as to provide access to talk recordings on demand afterwards. A link and individualised login information for the EMBL Media Site will be sent shortly before the conference. Recorded talks will be saved here until 09 April 2021. When accessing this service, we strongly recommend that you use the preferred browser Chrome.</p>
  <p><a href="https://gather.town/">Gather Town</a> will be used for most of the non-structured, social events, such as coffee and lunch breaks. This platform gives each participant an avatar and provides a range of mechanisms designed to facilitate interaction including one-on-one meetings and small group discussion.</p>

  <h4>Time zone</h4>
  <p>The programme is based on Central European Time (CET) and will be streamlined live; immediately afterwards, recordings of the talks will be available to all participants. To help those in different time zones, we are planning interactive sessions during the conference breaks that will provide multiple opportunities to engage with speakers as well as other conference participants.</p>

  <h4>Contacting speakers &amp; partcipants</h4>
  <p>Questions to speakers can be asked during or after the talks in the live streaming platform. The session chair will moderate the questions and share them with the speakers. These can be answered either immediately following the presentation, or during 'meet-the-speaker' events that will run in the break folowing each session. You will also have the option to contact speakers (as well as other  partcipants) via direct message.</p>

  <h4>Code of Conduct</h4>
  <p>VIZBI 2021 is hosted by <a href="https://www.embl.de">EMBL</a>, so that participants need to follow the <a href="https://www.embl.de/training/events/info_participants/codeofconduct/index.php">EMBL Code of Conduct</a>. In addition, please review the <a href="https://www.embl.de/training/events/info_participants/terms/index.html">EMBL Terms and Conditions</a> for conference participants.</p>
</template>
