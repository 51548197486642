<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <!-- <div v-show="host.match(/^localhost|^192/)" class="debugMessage">
    <p>vimeoHash = {{ vimeoHash }}</p>
    <p>showcase = {{ showcase }}</p>

    <p>description {{ description }}</p>

    <p>signedIn = {{ signedIn }}</p>
    <p>signInRequired = {{ signInRequired }}</p>
    <p>lookup_value = {{ lookup_value }}</p>
    <p>route.path = {{ $route.path }}</p>
  </div> -->
  <SignIn v-if="signInRequired" :to="showcase" @signedIn="get_meta_data()" />
  <figure v-if="options && options.showcases">
    <div id="wrapper" ref="mediaWrapper">
      <transition-group name="fade" tag="div">
        <!-- https://stackoverflow.com/q/55006789 -->
        <!-- https://developer.vimeo.com/player/sdk/embed -->
        <vue-vimeo-player
          v-if="lookup_value"
          class="child"
          :video-id="lookup_value"
          :options="{responsive: true, title: false, portrait: false, playsinline: false, byline: false}" />
        <img
          v-else-if="options.thumbnails['base_link'] && options.thumbnails['sizes'][3]"
          :src="options.thumbnails['base_link'] + options.thumbnails['sizes'][3]"
          class="child" />
        <p v-else>No video or thumbnail available</p>
      </transition-group>
    </div>
    <figcaption>
      <h4 v-if="name">{{ name }}</h4>
      <p v-if="description">{{ description }}</p>
    </figcaption>
  </figure>
  <div v-if="goToVideosButton">
    <p class="message">Unknown video identifier '{{ vimeoHash }}'</p>
    <ButtonBespoke @click="$router.push({name: 'Videos'})">Show all Videos</ButtonBespoke>
  </div>
</template>

<script>
import ButtonBespoke from "@/components/ButtonBespoke";
import SignIn from "@/components/SignIn";
import stringify from "json-stringify-safe";
import {vueVimeoPlayer} from "vue-vimeo-player";
export default {
  name: "VideoBespoke",
  components: {ButtonBespoke, SignIn, vueVimeoPlayer},
  props: {options: {type: Object, required: true}},
  data() {
    return {
      goToVideosButton: false, // if vimeoHash invalid, goes back to Videos
      host: window.location.host,
      lookup_value: "",
      lookup_values: [], // test - see history of values change
      //media_width: '640',
      showcase: "",
      signedIn: false,
      signInRequired: false,
      vimeoHash: this.$route.params.vimeoHash,
      name: "",
      description: ""
    };
  },
  computed: {
    api() {
      let api = process.env.VUE_APP_API;
      let host = window.location.host.replace(/:\d+$/, "");
      // for a dev server, replace localhost with local network IP
      // this enables dev testing on iPhone etc.
      if (api.match(/localhost/) && host.match(/^\d+/)) {
        this.log(`this is a dev server running on a local network IP`);
        // changing from localhost to local network IP address
        api = api.replace(/localhost/, host);
      }
      return api;
    }
  },
  created() {
    window.addEventListener("resize", this.matchWidth);
  },
  async mounted() {
    this.log("Video mounted()");
    this.matchWidth(); // check initial window width
    this.log(`vimeoHash initially set to "${this.$route.params.vimeoHash}"`);
    // this.showcase = this.options.listedVideoShowcase;
    let vimeoHash = await this.$route.params.vimeoHash;
    this.showcase = await this.options.get_showcase(vimeoHash);
    this.name = this.options.showcases[this.showcase]["videos"][vimeoHash].name;
    this.description = this.options.showcases[this.showcase]["videos"][vimeoHash].description;

    // this.log(`this.showcase = ${this.options.get_showcase("e004793e5c")}`);
    this.log(`this.showcase is now = ${this.showcase}`);
    if (window.location.hash) {
      this.log("URL has token; let SignIn process it; delay metadata fetch");
      this.signInRequired = true;
      return;
    }
    this.log("URL has no token, so fetch metadata and options.thumbnails");
    // initially, assume video is listed
    if (this.options.get_privacy(this.$route.params.vimeoHash) && !window.localStorage.loginToken) {
      this.signInRequired = true;
    } else {
      await this.get_meta_data();
    }
    if (this.options.thumbnails && this.options.thumbnails["base_link"]) {
      this.log(`thumbnails = ${this.options.thumbnails["base_link"]}`);
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.matchWidth);
  },
  methods: {
    // eslint-disable-next-line
    log(message) {
      if (process.env.NODE_ENV === "development") {
        console.log(message); // uncomment to show logs from this component
      }
    },
    matchWidth() {
      // https://stackoverflow.com/q/44948714
      //this.log(`media_width = ${this.$refs.mediaWrapper.clientWidth}`);
      // call whenever the window is resized
      this.media_width = this.$refs.mediaWrapper.clientWidth;
    },
    async get_meta_data() {
      if (!this.$route.params.vimeoHash) return;
      let vimeoHash = this.$route.params.vimeoHash;
      this.log(`get_meta_data(${vimeoHash})`);
      if (localStorage.loginToken) {
        this.signedIn = true;
      }
      this.goToVideosButton = false; // hide button to go back to Videos
      let response = await this.get_lookup_value(vimeoHash);
      if (!response) {
        return console.error(`No response from backend server.`);
      }
      this.log(`get_lookup_value returned`);
      if (!response.headers) {
        return console.error(`lookup response has no headers.`);
      }
      this.log(`lookup_value has headers`);
      this.lookup_value = await response.headers.get("lookup_value");
      let responseJson = await response.json();
      if (responseJson.showcase && !this.options.listedVideoShowcase) {
        // if this is an unlisted video
        this.unlistedVideoShowcase = responseJson.showcase;
      } else {
        this.log(`No event found for this video`);
      }
      if (this.lookup_value) {
        this.log(`Found lookup_value = ${stringify(this.lookup_value)}`);
        this.lookup_values.push(this.lookup_value);
        // must be signed in, so ...
        this.signedIn = true;
        this.signInRequired = false;
        if (responseJson && responseJson.loginToken) {
          // update localStorage loginToken if it has expired
          window.localStorage.loginToken = responseJson.loginToken;
          window.localStorage.events = responseJson.events;
        }
        this.log(`hash = ${window.location.hash}`);
        this.log(`events = ${window.localStorage.events}`);
        return;
      } else {
        this.log(`No lookup_value returned`);

        if (!responseJson.action) {
          this.invalidHash(responseJson);
        } else if (responseJson.action.match(/login/)) {
          this.log(`User needs to sign-in to ${responseJson.showcase}`);
          this.signInRequired = true;
          this.showcase = responseJson.showcase;
        } else if (responseJson.action.match(/register/)) {
          this.log(`User needs to register for ${responseJson.showcase}`);
          this.signInRequired = true;
          this.showcase = responseJson.showcase;
        } else if (responseJson.action === "invalid_login_token") {
          this.log(`responseJson.action = ${responseJson.action}`);
          window.localStorage.removeItem("loginToken");
          window.localStorage.removeItem("events");
          this.signedIn = false;
          this.signInRequired = true;
        } else {
          this.invalidHash(responseJson);
        }
      }
    },
    invalidHash(responseJson) {
      // E.g., response.json({status: 'no video'}).end;
      console.warn(`Response = ${stringify(responseJson)}`);
      console.warn(`Probably caused by invalid vimeoHash in URL`);
      this.goToVideosButton = true; // show button to go back to Videos
    },
    async get_lookup_value(vimeoHash) {
      this.log(`get_lookup_value(${vimeoHash})`);
      try {
        const response = await fetch(`${this.api}/videos/lookup/${vimeoHash}`, {
          method: "GET",
          //cache: 'force-cache', // needed for client cache to work?
          headers: {
            //'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.loginToken}`
          }
        });
        if (!response.ok) {
          // https://dmitripavlutin.com/javascript-fetch-async-await/
          throw new Error("HTTP error " + response.status);
        }
        return response;
      } catch (error) {
        console.error(`Problem accessing vizbi backend: ${error}`);
      }
    },
    removeVimeoHash() {
      this.log(`window.location = ${window.location}`);
      this.$router.push({name: "Videos"});
    }
  }
};
</script>

<style scoped>
.embargo {
  color: #929292;
  font-style: italic;
  font-weight: 300;
}

figure {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
  width: 100% !important;
}
#wrapper {
  position: relative;
  /* min-height: 360px; based on thumbnail chosen */
  padding-bottom: calc(100% / (640 / 360)); /* Replace "16 / 9" with your desired aspect ratio */
}
#wrapper .child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust the object-fit property as needed */
}
figcaption {
  /* flex: 1; */
}
#thumbnail-large {
  cursor: default;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

.child {
  width: 100%;
}
/* img.child {
  position: absolute;
} */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-leave,
.v-enter-to {
  opacity: 1;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

.debugMessage {
  color: red;
}
</style>
