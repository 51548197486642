// updates the URL hash with parameters separated by '&'

const log = (message) => console.log(message); // eslint-disable-line
import {useRoute} from "vue-router";

export function useUrlHash() {
  const route = useRoute(); // this gets run in the parent
  function setUrlHash(...parameters) {
    // silently update url without triggering vue router
    // https://stackoverflow.com/q/51337255/
    parameters = parameters.filter(Boolean);
    const parameterString = parameters.join("&");
    log(`useUrlHash(${parameterString})`);
    if (parameterString) {
      history.pushState({}, null, `${route.path}#${parameterString}`);
    } else {
      history.pushState({}, null, `${route.path}`); // empty hash
    }
  }
  return {setUrlHash};
}
