// Fetch and process one page of flickr photos

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

const test = function (year, photo) {
  if (year !== "2016") return;
  if (photo.url_n.includes("1590/25393393590_9ab10c4385_n")) {
    // photo.id === "25393393590"
    // https://live.staticflickr.com/1590/25393393590_9ab10c4385_n.jpg
    log(`Test:: found Fred Brooks photo in flick data`);
  }
  if (photo.url_n.includes("1472/26323809936_0540e57e0e_n")) {
    // photo.id === "26323809936"
    log(`Test: found Minardo photo in flick data`);
  }
};

export function processFlickrSearchData(photoData) {
  // process a page of Flickr Photo Data
  let output = {
    byYear: {},
    byID: {},
    years: [],
    yearsHidden: [],
    total: 0,
  };
  photoData.forEach((photo) => {
    if (output.byID[photo.id]) return; // skip duplicates
    // "datetaken":"2019-03-15 07:21:52"
    let year = photo.datetaken.match(/^(\d+)-/)[1];
    log(`processPhotoData: year = ${year}`);
    if (!output.byYear[year]) {
      // first photo from this year
      output.byYear[year] = [];
    }
    // accumulate photo data
    output.byYear[year].push(photo);
    output.byID[photo.id] = {
      id: photo.id, // needed for highlighting
      url: photo.url_o || photo.url_l, // in case original not available
      // https://www.flickr.com/services/api/misc.urls.html
      owner: photo.owner,
      ownername: photo.ownername,
      //'source': `https://flickr.com/photo.gne?id=${photo.id}`
    };
    // https://www.flickr.com/help/forum/en-us/126730/
    if (!output.years.includes(year)) {
      log(`processPhotoData: add ${year} to 'years'`);
      output.years.push(year);
      log(`processPhotoData: years = ${output.years}`);
      output.yearsHidden.push(year);
    }
    output.total++;
    test(year, photo); // search for Fred Brooks photo
  });
  return output;
}

// Code below is a typical flickr response:
let flickrResponse = {
  photos: {
    page: 1,
    pages: 30,
    perpage: 30,
    total: 894,
    photo: [
      {
        id: "52852568788",
        owner: "40300403@N06",
        secret: "51130d8086",
        server: "65535",
        farm: 66,
        title: "VIZBI 2023",
        ispublic: 1,
        isfriend: 0,
        isfamily: 0,
        datetaken: "2023-03-29 10:56:59",
        datetakengranularity: 0,
        datetakenunknown: "0",
        ownername: "Se\u00e1n O'Donoghue",
        views: "2",
        url_n: "https://live.staticflickr.com/65535/52852568788_51130d8086_n.jpg",
        height_n: 101,
        width_n: 320,
      },
      {},
    ],
  },
};
flickrResponse.photos = {};
