// Copyright: Seán I. O'Donoghue
// Replace Login.vue; Work out if user is logged in.
// Status: basic call works nicely; check response for
// TODO: remove Origin header > disable fetching

const log = (message) => console.log(); // eslint-disable-line
import {call_the_backend_api} from "./call_the_backend_api.js";

function process_the_response(response) {
  return JSON.stringify(response); // 10
}

let output = (async function () {
  let email = "test@gmail.com"; // test@gmail.com | sean@odonoghuelab.org
  let response = await call_the_backend_api(email, "Masterclass 2023");
  let output = process_the_response(response);
  // https://vuejs.org/guide/reusability/composables.html#return-values
  return output; // 10
})(); //?
output;
