// takes two arrays; returns merged array of unique values

import stringify from "json-stringify-safe";
export function arrayMergeUnique(array1, array2) {
  // return array of only unique values
  // https://stackoverflow.com/q/54090245
  array1 = array1.concat(array2); // merge two arrays
  let primitives = {};
  let objects = {};
  let output = [];
  for (const item of array1) {
    if (typeof item === "object") {
      // https://stackoverflow.com/q/1068834
      objects[stringify(item)] = item;
    } else {
      primitives[item] = item;
    }
  }
  output = output.concat(Object.values(primitives).sort().reverse());
  output = output.concat(Object.values(objects));

  //console.log(`arrayMerge: ${stringify(output)}`);
  return output;
}
/*
let [a, b] = [
  [1, 1, 2, 5, "34", 2],
  ["34", { x: 1 }],
];
a;
b; //
let c = arrayMergeUnique(a, b);
console.log(stringify(c));
/* */
