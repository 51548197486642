<template>
  <h3>Masterclass</h3>
  <!-- v-if prevents errors when switching routes -->
  <component :is="page" v-if="$route.name" />
  <PeopleGallery :highlights="true" name="Speakers" category="Masterclass" />
  <!-- @updateDay="selectDay" @updateCategory="selectCategory" -->
  <h4 id="program">Preliminary program</h4>
  <ProgramTable session="Masterclass" :description="true" />
</template>

<script setup>
import PeopleGallery from "@/components/People/Gallery.vue";
import ProgramTable from "@/components/Program/Table.vue";
import {useRoute} from "vue-router";
const route = useRoute();
import {shallowRef} from "vue";
let page = shallowRef();
try {
  if (route.params.year) {
    let year = route.params.year;
    page = shallowRef(require(`@/assets/years/${year}/Masterclass/Index.vue`).default);
  }
} catch (error) {
  console.error(`Contact.vue|${route.params.year} error: ${error}`);
}
</script>

<style scoped>
h4 {
  display: inline;
  float: left;
}
</style>
