<template>
  <p>All conference participants will have the opportunity to present a scientific poster describing technical or research advances involving biological data visualization. Your poster should be accompanied by a pre-recorded &lsquo;Lightning Talk&rsquo; (max. 60 seconds) in which you introduce yourself and your work to all VIZBI participants (see details <a href="#Lightning">below</a>). Note that advertisements for services or production will not be accepted.</p>
  <p>In addition, each participant is invited to upload one artistically-inspired image (see details <a href="#Art">below</a>), which will be unveiled during the Art &amp; Biology event (Thursday evening). These will also be published on the VIZBI
    website.</p>
  <p>To upload your poster or artwork, you need to first <a href="/2021/Registration/">register</a> for the conference and complete payment by <b>March 1, 2021</b>. Once payment has been confirmed, you will receive an email containing a link to the upload page. The deadline for submitting your upload is 23:00 CET on <b>March 15, 2021</b>.</p>
  <p>When preparing your work for submission, please refer to the detailed instructions below.</p>
  <h4 id="Submission">Poster Submission</h4>
  <p>The link for the poster upload page will be sent to you once you have registered and completed the payment. Please prepare one main image in PNG format (&#8804; 50 Mbytes) &mdash; this could show your entire poster or only a highlight of your work. Also prepare an abstract describing your work (single paragraph, &#8804; 1,200 characters), ideally in language accessible to scientists who are not specialists in your particular domain. If accepted, your submission will be published as part of the <a href="/Posters/">VIZBI poster collection</a> on the first day of the conference.</p>
  <p>The deadline for uploading your VIZBI 2021 poster image, abstract, and lightning talk presentation is 23:00 CET on <b>March 15, 2021</b>. Immediately after uploading, you will be able to preview a zoomable version of your poster, showing how it will appear on the VIZBI poster pages (for an example, see <a href="https://vizbi.org/Posters/2010/F06">here</a>). Please take this opportunity to verify that the title, abstract, affiliations, and poster image are in good order &mdash; if not, simply re-upload a modified version (this will overwrite your previous submission). Our policy is to accept all posters relevant to the scientific themes of the conference &mdash; but to reject submissions focused on advertising products or services. We will notify you shortly after the upload deadline in case there are any policy or technical issues with your uploaded poster, slides, or video.</p>
  <h4 id="Lightning">Lightning Talks</h4>
  <p>Each poster can be accompanied by a ≤60 seconds video, where you introduce yourself, and give the audience a quick overview of your poster. We encourage you to prepare well and be creative to get your message across effectively in this short time. In preparing your lightning talk video, please note the following:</p>
  <ul>
    <li>Supported video formats are AVI, MP4 or MOV.</li>
    <li>Maximum Resolution: 1920 × 1080.</li>
    <li>Maximum File Size: 250 MB.</li>
    <li>Videos longer than 60s will not be accepted by the upload system.</li>
    <li>After upload, you will be directed to a unique URL that allows you to preview and test exactly how your lightning talk video will appear.</li>
  </ul>
  <h4 id="Poster_Session">Virtual Poster Session</h4>
  <p>After you upload your poster submission, we will send an email telling you in which session your poster has been assigned, and will provide you with a unique poster number. Technical details for the virtual poster session will be confirmed nearer the conference.</p>
  <h4 id="Art">Art &amp; Biology entries</h4>
  <p>In addition to the scientific poster, each conference participant is invited to submit one artistically-inspired image, as part of our Art &amp; Biology Award. We can only accept entries that are clearly related to the scientific themes of the conference, and that do not violate policies of our host. Accepted entries will first be revealed as part of <a href="http://vizbi.org/Posters/?category=Art">Art &amp; Biology evening event</a>. The award for best Art &amp; Biology entry will be decided by popular vote and announced at the Awards Ceremony during the conference closing session. Participants are asked to judge Art &amp; Biology entries based on how visually compelling and original they are in communicating biological insight; it may help to see <a href="http://vizbi.org/Posters/?category=Art">Art &amp; Biology entries from previous VIZBI meetings</a>. All Art &amp; Biology entries will be published on the VIZBI website, alongside the scientific posters.</p>
</template>
