// Copyright: Sean O'Donoghue
// Test the useRoute function
// https://stackoverflow.com/q/66683113

import { useRoute } from "vue-router";
import { computed } from "vue";

export function useRouteTest() {
  const route = useRoute();
  const path = computed(() => {
    console.log(`step 1`);
    if (!route) {
      console.log(`step 2`);
      return;
    }
    if (!route.path) {
      console.log(`step 3`);
      return;
    }
    console.log(`step 4`);
    return route.path;
  });
  return { path };
}
