<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <button v-if="label" ref="tag" :class="{selected: selected === label}" @click="updateParent">
    <span>{{ label }}</span>
    <span v-if="selected === label" class="cancel"> ✕ </span>
  </button>
</template>

<script setup>
const log = (message) => console.log(); // eslint-disable-line

const props = defineProps({
  label: {default: ""},
  selected: {default: ""}
});
const emit = defineEmits(["update"]);

import {ref} from "vue";
const tag = ref(null);
function updateParent() {
  let response = ""; // response if tag was already selected
  if (props.selected && props.selected === props.label) {
    // if something was already selected, and it was this label
    // then lose focus on this tag
    //event.target.blur();
    tag.value.blur();
    // https://stackoverflow.com/q/61776432
  } else {
    // if tag was not already selected, click sends back the label
    response = props.label;
  }
  log(`updateParent: label = ${props.label}, response = ${response}`);
  emit("update", response); // send response to parent
}
</script>

<style scoped>
/* scoped = CSS limit to this component only */
button {
  /* Longer tag names break into two lines for higher values */
  padding: 0.4em;
  margin-top: 0.25em;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  line-height: 10px;
  border: none;
  color: white;
  background: #a7a7a7;
  border-radius: 0.35em;
  text-shadow: none;
  box-shadow: none;
  display: inline;
}
button + button {
  margin-left: 0.4em;
}
button:hover {
  background-color: #d5d5d5;
}
/* https://stackoverflow.com/q/24923647 */
a:hover:not(:focus) {
  color: #a7a7a7; /* when it has focus but not hover, back to normal */
}
button.selected {
  color: white;
  opacity: 1;
  background-color: var(--brand-color);
}
button:hover.selected {
  filter: brightness(120%);
}
span.cancel {
  padding-left: 0.19em;
}
</style>
