<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div v-if="false">
    <p>finished = {{ finished }}</p>
    <p>showWhenFinished = {{ showWhenFinished }}</p>
  </div>
  <div v-if="!finished" id="counter">
    <ul v-for="(count, index) in display" :key="index">
      <li class="count">{{ count }}</li>
      <li class="label">{{ labels[index] }}</li>
    </ul>
  </div>
  <div v-else-if="finished && showWhenFinished">Boom</div>
</template>

<script>
// https://leemartin.dev/how-to-develop-a-countdown-clock-using-vue-and-luxon-for-rockstars-e3ecff9338ef
const {DateTime, Duration} = require("luxon");
DateTime.local().set({milliseconds: 0}); // set ms to zero
//import stringify from 'json-stringify-safe';
export default {
  name: "Countdown",
  props: {
    showWhenFinished: {type: Boolean, default: false},
    until: {type: Object, default: () => {}}
  },
  emits: ["hideCounter"], // https://stackoverflow.com/q/64220737
  data() {
    return {
      now: DateTime.local(),
      labels: ["days", "hours", "mins", "secs"],
      tick: null // used as a reference to the setInterval() method
    };
  },
  computed: {
    remaining() {
      return this.until.diff(this.now).toObject();
    },
    remainingFormated() {
      return Duration.fromObject(this.remaining).toFormat("dd hh:mm:ss");
    },
    display() {
      let display = this.remainingFormated.match(/^(\d+) (\d+):(\d+):(\d+)/);
      display.shift();
      //console.log(`display = ${display}`);
      return display;
    },
    finished() {
      if (this.now < this.until) {
        return false;
      }
      this.log(`finished`);
      this.$emit("hideCounter", this.until);
      return true;
    }
  },
  mounted() {
    this.log(`Countdown mounted`);
    this.tick = this.startTimer();
    //this.log(`tick type = ${typeof tick}`);
    //https://usefulangle.com/post/280/settimeout-setinterval-on-inactive-tab
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        this.log("tab now inactive - temporarily clear timer");
        clearInterval(this.tick); // stops 'now' from updating
      } else {
        this.log("tab is active again, so restarting timer");
        this.tick = this.startTimer();
      }
    });
  },
  methods: {
    log(message) {
      // eslint-disable-line
      if (process.env.NODE_ENV === "development") {
        console.log(message); // uncomment to show logs from this component
      }
    },
    startTimer() {
      return setInterval(() => {
        // updates 'now' to local time every 0.1 second
        this.now = DateTime.local();
      }, 100);
    }
  }
};
</script>

<style scoped>
#counter {
  display: flex;
  flex-direction: row;
  gap: 20px; /* horizontal spacing between children */
  align-items: center; /* aligns children + text within children */
  text-align: center;
  margin: 0px;
  padding: 0px;
}

ul {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0px;
  padding: 0px;
  min-width: 0; /* prevents from overflowing */
}

li {
  list-style-type: none;
  color: red;
  margin: 0px;
  padding: 0px;
  min-width: 0; /* prevents from overflowing */
}

.count {
  font-size: 2rem;
}
</style>
