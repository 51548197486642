<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <ul v-if="!$route.params.vimeoHash" id="legend">
    <!-- <li v-if="registrationRequired && !loginToken">
      <img src="@/assets/img/lock-orange.png" class="lock" />
      = Registration required
    </li> -->
    <li v-if="registrationRequired">
      <!--  && loginToken  -->
      <img src="@/assets/img/lock-orange.png" class="lock" />
      = Registration required
    </li>
    <li v-else>
      <img src="@/assets/img/unlocked.png" class="lock" />
      = Accessible with your login
    </li>
    <li>
      No icon = freely available
      <!-- accessible to all -->
    </li>
  </ul>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
// eslint-disable-next-line
const props = defineProps({showcases: {default: []}});
import stringify from "json-stringify-safe";
import {ref} from "vue";
// const loginToken = ref(window.localStorage.loginToken);
const registrationRequired = ref(true);
import {onMounted} from "vue";
onMounted(() => {
  log(`VideoLegend mounted`);
  log(`showcases = ${stringify(props.showcases)}`);
  let masterclasses = props.showcases.filter((showcase) => showcase.match(/Masterclass/i));
  if (!window.localStorage.events) return;
  let registrationRequiredLocal = false;
  for (let masterclass of masterclasses) {
    log(`masterclass = ${masterclass}`);
    if (!window.localStorage.events.includes(masterclass)) {
      registrationRequiredLocal = true;
      continue; // end for loop
    }
  }
  log(`registrationRequiredLocal = ${registrationRequiredLocal}`);
  registrationRequired.value = registrationRequiredLocal;
});
</script>

<style scoped>
/* scoped = CSS limit to this component only */
ul#legend {
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 8px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-weight: 300;
  font-size: 0.95rem;
}
li {
  display: inline;
}
li + li {
  margin-left: 6px;
}
li + li::before {
  margin-right: 6px;
  content: " | "; /* https://stackoverflow.com/q/9171699 */
}
img.lock {
  width: 0.8rem;
}
</style>
