// Copyright: Seán I. O'Donoghue
// properties from object2 are added to object1
// if the property is an array, the arrays are concatinated

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

//import { arrayMergeUnique } from "@/composables/useArrayMerge";
import {arrayMergeUnique} from "./useArrayMerge.js";
import stringify from "json-stringify-safe";
export function objectMergeConcatArrays(object1, object2) {
  Object.keys(object2).forEach((key) => {
    if (!object1[key]) {
      log(`objectMerge: ${key} not in object1, so add it`);
      object1[key] = object2[key];
      return;
    }
    // https://stackoverflow.com/q/767486
    if (object1[key].constructor === Array && object2[key].constructor === Array) {
      log(`objectMerge: merge arrays for key = ${key}`);
      object1[key] = arrayMergeUnique(object1[key], object2[key]);
    }
  });
  return object1;
}
/* Quokka test */
let [a, b] = [
  {photos: [1, 2, 3], 2: 5, 34: 2},
  {photos: [1, "34", 3], sub: {x: 1}}
];
a;
b; //
let c = objectMergeConcatArrays(a, b);
console.log(stringify(c));
/* */
