<template>
  <h3>Contact</h3>
  <!-- v-if prevents errors when switching routes -->
  <component :is="page" v-if="$route.name" />
</template>

<script setup>
import {useRoute} from "vue-router";
const route = useRoute();
import {shallowRef} from "vue";
let page = shallowRef();
try {
  if (route.params.year) {
    let year = route.params.year;
    page = shallowRef(require(`@/assets/years/${year}/Contact/Index.vue`).default);
  }
} catch (error) {
  console.error(`Contact.vue|${route.params.year} error: ${error}`);
}
</script>
