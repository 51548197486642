<template>
  <p>For inquiries relating to VIZBI workshop registration, CPP fellowships, sponsorship opportunities, or logistics please contact the <a href="https://www.embl.org/about/info/course-and-conference-office/">EMBL conference office:</a></p>
  <Email to="nathalie-sneider" :outstanding="true" />
  <p>For all inquiries relating to the Masterclass please contact:</p>
  <Email to="masterclass" :outstanding="true" />
  <p>For inquiries relating to scientific aspects of the workshop program please contact:</p>
  <Email to="vizbi-contact" :outstanding="true" />
</template>

<script>
  import Email from '@/components/Email.vue';
  export default {
    components: { Email }
  };
</script>

<style scoped>
  p:not(:first-child) {
    margin-top: 18px;
  }
</style>
