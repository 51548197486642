<template>
  <div id="switch" @click.stop="$emit('update', showBio)">
    <span v-if="showBio">
      <ButtonBespoke :outstanding="true" :shadow="false">Bio</ButtonBespoke>
      <span class="option">Talk</span>
    </span>
    <span v-else>
      <span class="option">Bio</span>
      <ButtonBespoke :outstanding="true" :shadow="false">Talk</ButtonBespoke>
    </span>
  </div>
</template>

<script>
import ButtonBespoke from "@/components/ButtonBespoke";
export default {
  name: "SwitchBespoke",
  components: { ButtonBespoke },
  props: {
    showBio: { type: Boolean, default: true },
  },
  emits: ["update"], // https://stackoverflow.com/q/64220737
};
</script>

<style scoped>
div#switch {
  display: inline-block;
  width: fit-content;
  border-radius: 6px;
  margin: 0px;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 207, 0.2);
  margin-left: auto;
  margin-right: 0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  text-align: left;
  text-shadow: none;
  color: #505050;
  background-color: #efedf1;
  cursor: normal;
}

span.option {
  padding: 0 10px; /* top/bottom, right/left */
  cursor: pointer;
}
</style>
