let a = {};
if (a.isEmpty) {
  console.log("a is empty");
} else {
  console.log("Not empty");
}

let path = "#65535/52852568788_51130d8086_n.jpg";
path;
let b = path.match(/\w+\/(\d+)_\w+\.\w+/);
if (b) console.log("yes!");

let url = "https://live.staticflickr.com/65535/52890725882_329ebc25ee_o.png";
let c = url.replace(/.*\.com\//, "");
c;

a = { one: 1, two: 2 };
b = { three: 3 };
