<template>
  <div>
    <a href="http://biovis.net"><img src="./img/BioVis.png" alt="BioVis" align="absmiddle"></a>
    <a href="http://www.digizyme.com/"><img src="./img/Digizyme.png" alt="Digizyme" align="absmiddle"></a>
    <a href="https://www.frontiersin.org/journals/bioinformatics"><img src="./img/Frontiers.png" alt="Frontiers" align="absmiddle"></a>
  </div>
  <h4>Affiliations</h4>
  <div>
    <a href="http://www.iscb.org/"><img src="./img/ISCB.jpg" alt="ISCB" title="ISCB"></a>
  </div>
  <h4>Media Partners</h4>
  <ul>
    <li><a href="http://embor.embopress.org/">EMBO reports</a>, an EMBO Press journal</li>
    <li><a href="https://jcs.biologists.org/">Journal of Cell Science</a>, a Company of Biologists journal</li>
    <li><a href="http://rsob.royalsocietypublishing.org/">Open Biology</a>, a Royal Society journal</li>
    <li><a href="https://peerj.com/">PeerJ</a></li>
    <li><a href="https://www.wiley.com/en-us">Wiley</a></li>
  </ul>
  <h4>About VIZBI Sponsorship</h4>
  <p>VIZBI is an international conference series on Visualizing Biological Data that has alternated primarily between the European Molecular Biology Laboratory in Heidelberg, Germany, and the Broad Institute of MIT and Harvard in Cambridge MA, USA, with support from the NIH and EMBO.</p>
  <p>VIZBI 2021 is held in partnership with EMBL, and affiliated with ISCB. The meeting offers opportunities to promote organisations engaged with either data visualisation or the life sciences. Your organisation would gain exposure in a community that includes some of the most knowledgeable researchers and professionals working in biological data visualisation today. We expect ~400 scientists, software developers, medical professionals, illustrators and designers from around the world will attend VIZBI 2021. Our 21 invited speakers will review the state-of-the-art approaches and challenges in visualizing data from all of biology. As sponsor, your brand would be advertised alongside these talks, when the videos and posters from VIZBI 2021 are published on the main VIZBI site, which is accessed by over 3,000 life scientists each month.</p>
  <p>If you are interested in promoting your organization to VIZBI participants, we would also be happy to work with you in tailoring a sponsorship package to suit your requirements and budget. For more information, see the links below, or contact the scientific organizers at:
    <Email to="vizbi-contact" :outstanding="false" />.
  </p>
  <ul>
    <li><a href="http://www.embl.de/training/events/sponsorship/">Sponsoring an EMBL conference</a></li>
    <li><a href="http://www.embl.de/training/events/sponsorship/mediapartnerships/">EMBL media partnerships page</a></li>
  </ul>
</template>


<script>
import Email from '@/components/Email.vue';
export default {
  components: {
    Email
  }
};
</script>
