<template>
  <h4>Primary sponsor</h4>
  <div>
    <a href="https://dornsife.usc.edu/bridge-institute/" target="_blank">
      <img v-if="displayMode === 'lightMode'" src="./img/Bridge-lightMode.png" title="USC Bridge Institute" />
      <img v-else src="./img/Bridge.png" title="USC Bridge Institute" />
    </a>
  </div>
  <h4>Co-sponsors</h4>
  <div>
    <a href="https://www.frontiersin.org/journals/bioinformatics" rel="nofollow">
      <img id="frontiers" src="./img/frontiers.svg" alt="FBINF" title="Frontiers in Bioinformatics" />
    </a>
    <a href="https://vcc.kaust.edu.sa/" rel="nofollow">
      <img id="kaust" src="./img/kaust.png" alt="KAUST VCC" title="KAUST Visual Computing Center" />
    </a>
  </div>
  <h4>Affiliations</h4>
  <div>
    <a href="http://www.iscb.org/" rel="nofollow">
      <img src="./img/ISCB.jpeg" alt="ISCB" title="ISCB" />
    </a>
    <a href="https://www.eg.org/" rel="nofollow">
      <img v-if="displayMode === 'lightMode'" src="./img/EG-lightMode.png" alt="Eurographics" title="Eurographics" />
      <img v-else src="./img/EG.png" alt="Eurographics" title="Eurographics" />
    </a>
    <a href="https://www.datavisualizationsociety.org" rel="nofollow">
      <img
        v-if="displayMode === 'lightMode'"
        src="./img/DVS-lightMode.png"
        alt="Data Visualization Society"
        title="Data Visualization Society" />
      <img v-else src="./img/DVS.png" title="Data Visualization Society" />
    </a>
  </div>
  <!--
  <h4>Media Partners</h4>
  <div>
    <a href="http://www.digizyme.com/" rel="nofollow">
      <img src="./img/Digizyme.png" alt="Digizyme" />
    </a>
    </a>
    <a href="https://iubmb.org/">
      <img src="./img/iubmb.jpeg" alt="IUBMB" title="International Union of Biochemistry and Molecular Biology" />
    </a>
    <a href="https://peerj.com/">
      <img src="./img/PeerJ.png" alt="PeerJ" title="PeerJ" />
    </a>
    <a href="http://rsob.royalsocietypublishing.org/">
      <img src="./img/rsob.png" alt="Open Biology" title="Open Biology, a Royal Society journal" class="openBiology" />
    </a>
  </div>
    -->
</template>

<script setup>
import {inject} from "vue";
const displayMode = inject("displayMode");
</script>

<style scoped>
/* Styles in this page are set in views/Sponsors.vue using the deep selector */
/* Some logos need individual adjustments */
/* https://stackoverflow.com/q/3029422 */

img#frontiers {
  height: 2.3rem !important;
}
.narrow img#frontiers {
  height: 1.7rem !important;
}
img#kaust {
  max-height: 5rem !important;
}
.narrow img#kaust {
  max-height: 3rem !important;
}
</style>
