<template>
  <p>
    We invite all in-person and remote participants to present a
    <router-link to="#submissions">Scientific Poster</router-link> and a 60 second
    <router-link to="#lightning">Lightning Talk</router-link> describing work on biological data visualization (we
    cannot accept submissions focused on advertising). Participants can also upload one biologically-inspired artwork
    for the <router-link to="#art">Art &amp; Biology Event</router-link>. We encourage participants to consider
    enhancing their poster or artwork with augmented reality content (<router-link to="#AR">see below</router-link>
    for details).
  </p>
  <p>
    To upload your poster or artwork, you need to first <a href="/2024/Registration/">register for the conference</a>.
    You will then receive a confirmation email containing a link to the upload page. The deadline for submitting your
    upload is 11:59pm PST on <s>February 23, 2024</s> Friday, <b>March 8, 2024</b>. Further details on preparing your
    submission are given below.
  </p>
  <h4 id="submissions">Poster Submission</h4>
  <p>
    Please prepare one main image in PNG format (&#8804; 50 MB). Also prepare an abstract describing your work (single
    paragraph, &#8804; 1,200 characters), ideally in language accessible to scientists who are not specialists in your
    particular domain.
  </p>
  <p>
    Once your poster is ready, upload it using the link provided in the email registration confirmation email (upload
    deadline: <s>February 23, 2024</s> <b>March 8, 2024</b>). Immediately after uploading, you will be able to preview a
    zoomable version of your poster, showing how it will appear on the VIZBI poster pages (for an example, see
    <a href="https://vizbi.org/Posters/2010/F06">here</a>). Please take this opportunity to verify that the title,
    abstract, affiliations, and poster image are in good order - if not, simply re-upload a modified version (this will
    overwrite your previous submission). Our policy is to accept all posters relevant to the scientific themes of the
    conference. We will notify you shortly after the upload deadline in case there are any policy or technical issues
    with your submission.
  </p>
  <p>
    If accepted, your submission will be published as part of the
    <a href="/Posters/">VIZBI poster collection</a> on the first day of the conference.
  </p>

  <h4 id="on-site">Printing &amp; displaying your poster on-site</h4>
  <p>
    <strong>Finding your poster's session.</strong> If you are attending VIZBI 2024 in-person, your poster will have
    been assigned a code beginning with a letter corresponding to its scheduled session.<br />Session codes are: A -
    Wednesday Lunch, B - Wednesday Evening, C - Thursday Lunch, D - Friday Lunch.
  </p>
  <p>
    <strong>Putting up your poster.</strong> Posters should be either A0 portrait or A1 landscape format. Boards are
    numbered (corresponding to the numeric part of your poster's code). Tables will also be available for you to use for
    laptops in order to demonstrate your work.
  </p>
  <p>
    <strong>When to put up your poster.</strong> We recommend you put up your poster during the coffee-break preceding
    your session. That way, your poster will already be on display at the beginning of the lightning talks for your
    session.
  </p>

  <h4 id="lightning">Lightning Talks</h4>
  <p>
    Each scientific poster session is preceded by a &lsquo;Lightning Talk&rsquo;, where you come on stage, introduce
    yourself, and give the audience a quick overview of your poster. Since you have only 1 minute to address the
    audience, you are encouraged to prepare well and be creative in order to get your message across. In preparing your
    slides, please note the following:
  </p>
  <ul>
    <li>You can upload up to three images to accompany your presentation.</li>
    <li>
      Each image should be in PNG format and be designed to fit onto a screen with aspect ratio of 16:9. The projector
      supports a resolution of up to 2560 &#215; 1440.
    </li>
  </ul>
  <p>
    Remote participants should not submit images, but instead upload a video that will then be screened during the
    Lightning session (see <a href="#Virtual">below</a>). Note that normal participants cannot submit videos.
  </p>

  <h4 id="virtual">Remote Participants</h4>
  <p>
    Remote participants can present their work by uploading a poster together with a ≤60 second video. We encourage you
    to be creative to get your message across effectively in this short time. Please note the following:
  </p>
  <ul>
    <li>Supported video formats are AVI, MP4 or MOV.</li>
    <li>Maximum Resolution: 1920 × 1080</li>
    <li>Maximum File Size: 250 MB</li>
    <li>Videos longer than 60s will not be accepted by the upload system.</li>
    <li>
      After upload, you will be directed to a unique URL that allows you to preview and test exactly how your lightning
      talk video will appear.
    </li>
  </ul>
  <p>
    Your video will be screened during one of the Lightning Talk sessions, immediately before your poster session, which
    for virtual presenters take place via the VIZBI online platform. To ensure VIZBI participants can contact you, we
    recommend indicating your availability throughout the meeting and including contact details (such as a Skype
    identifier), either in your video as part of your poster abstract.
  </p>

  <h4 id="art">Art &amp; Biology entries</h4>
  <p>
    We invite each workshop participant to submit one biologically-inspired art image, as part of our Art &amp; Biology
    Award. We can only accept entries clearly related to the themes of the conference that do not violate policies of
    our host. Accepted entries will first be revealed as part of
    <a href="http://vizbi.org/Posters/?category=Art">Art &amp; Biology evening event</a>. The award for best Art &amp;
    Biology entry will be decided by popular vote and announced at the Awards Ceremony during the conference closing
    session. Participants are asked to judge Art &amp; Biology entries based on how visually compelling and original
    they are in communicating biological insight; it may help to see Art &amp; Biology entries from
    <a href="http://vizbi.org/Posters/?category=Art">previous VIZBI meetings</a>. All Art &amp; Biology entries will be
    published on the VIZBI website, alongside the scientific posters.
  </p>

  <h4 id="ar-art">Augmented artwork</h4>
  <p>
    We encourage you to submit artwork augmented using <a href="https://www.schol-ar.io">Schol-AR</a>. Although there is
    no plan for a special AR exhibit at VIZBI 2024, we may be able to feature submissions in a future VIZBI.
    <br /><br />
    Please ensure your artwork can be printed within about 30 ✕ 40 inches ideally at 120 DPI. When preparing your
    augmented artwork, be sure that the Schol-AR QR code is incorporated into the final image that you upload. Note that
    augmented images in scientific posters will not be part of the exhibit. If you would like to feature an image from
    your poster in the exhibit, please submit it as a separate art &amp; biology artwork.
  </p>
  <HeroImage file="/2023/Posters/img/AR-exhibit.jpg" credit="Christian Stolte" />
</template>
<script>
//import Email from '@/components/Email.vue';
// export default ({
//   name: 'Index',
//   components: { Email },
// });
</script>
