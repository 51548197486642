<template>
  <router-link v-if="video" :to="`/Videos/${video}`" :class="align">
    <img src="@/assets/img/recording.png" title="Click to open video recording." />
  </router-link>
  <img
    v-else-if="event.mode && event.mode.match(/Online/i)"
    src="@/assets/img/onsite+online.png"
    title="This is an online + on-site event." />
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
const props = defineProps(["event", "align"]);

import {computed} from "vue";
const video = computed(() => {
  // handle case when 'event' comes from program or people
  return props.event.video || props.event.Video;
});
</script>

<style scoped>
img {
  width: 14px;
}
a {
  height: 100%; /* expand to fill height and width of parent */
  /* background-color: lightgray; */
}
a.right {
  margin-left: 0.5em;
}
</style>
