<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <figure v-cloak :key="`${name}-fig`">
    <div
      v-if="highlights"
      :key="`${name}-placeholder`"
      class="img-placeholder"
      @click="$emit('updateHighlightedPerson', name)">
      <Suspense>
        <PersonHeadshot :key="`${name}-headshot`" :person="name" />
      </Suspense>
    </div>
    <a v-else :key="`${name}-placeholder`" :href="url" rel="noopener">
      <Suspense>
        <PersonHeadshot :key="`${name}-headshot`" :person="name" />
      </Suspense>
    </a>
    <figcaption>
      <!-- person's name -->
      <a :href="url" rel="noopener">
        {{ name }}
      </a>
      <!-- affiliation -->
      <span class="affiliation"> ({{ affiliation }})</span>
      <span v-if="topic && selectTopic(name) !== 'Session Chairs'" class="topic">, '{{ selectTopic(name) }}'</span>
      <br />
      <!-- session / category tags -->
      <span v-if="tags" class="tags">
        <span v-for="personCategory of categories.split(/\s*;\s*/)" :key="personCategory">
          <Tag :label="personCategory" :selected="category" @update="updateParentCategory" />
        </span>
        <!-- date tag -->
        <span v-if="date">
          <Tag
            v-for="personDay of date.split(/\s*;\s*/)"
            :key="personDay"
            :label="personDay"
            :selected="day"
            @update="updateParentDay" />
        </span>
      </span>
    </figcaption>
  </figure>
</template>

<script setup>
import {defineAsyncComponent} from "vue";
const PersonHeadshot = defineAsyncComponent(() => import("@/components/PersonHeadshot.vue"));
import stringify from "json-stringify-safe";
import Tag from "@/components/Tag";

const props = defineProps({
  affiliation: {type: String, default: ""},
  categories: {type: String, default: ""},
  category: {type: String, default: ""},
  date: {type: String, default: ""},
  day: {type: String, default: ""},
  highlights: {type: Boolean, default: false},
  name: {type: String, default: ""},
  topic: {type: String, default: ""},
  tags: {type: Boolean, default: false},
  url: {type: String, default: ""}
});

const emits = defineEmits(["updateHighlightedPerson", "updateParentCategory", "updateParentDay"]);

// Methods
function log(message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
}

function selectTopic() {
  if (!props.categories) {
    console.error(`Shouldn't happen: no categories for ${props.name}`);
    return;
  }
  let categories = props.categories.split(/\s*;\s*/);
  let thisCategoryIndex = 0; //default value when no category is set
  if (props.category) {
    // if a category is selected, is it first or second for this person?
    thisCategoryIndex = categories.indexOf(props.category);
    if (thisCategoryIndex === -1) {
      console.error(`Shouldn't happen: ${props.name} lacks ${props.category}`);
      return;
    }
  }
  let topics = props.topic.split(/\s*;\s*/);
  log(`topics = ${stringify(topics)}`);
  return topics[thisCategoryIndex];
}

function updateParentCategory(category) {
  log(`updateParentCategory(${category})`);
  emits("updateParentCategory", category); // send selected session to parent
}

function updateParentDay(day) {
  emits("updateParentDay", day); // send selected day up to parent
}
</script>

<style scoped>
figure {
  /* width: 100%; */
  min-width: 0%; /* NEW; needed for Firefox */
  margin: 0px;
  padding: 0px;
}
figcaption {
  hyphens: auto;
  caption-side: bottom;
  font-size: 13px;
  line-height: 16px;
  padding-top: 0px;
  /*  */
  padding-left: 0px;
  /* counter act border-spacing */
  padding-right: 0px;
  /* counter act border-spacing */
  padding-bottom: 4px; /* 8px */
  /*  */
  margin: 0px;
  margin-top: 5px;
  text-align: left;
}
/* https://developer.mozilla.org/en-US/docs/Web/CSS/flex-wrap*/
figcaption span button {
  hyphens: none;
  font-size: 9.9px;
}
figcaption a {
  /* person's name */
  color: grey;
  font-size: 16px;
}
.narrow figcaption a {
  /* person's name */
  font-size: 13px;
}
.medium figcaption,
.medium button {
  font-size: 10px;
}
/* very medium */
.narrow figcaption,
.narrow button {
  font-size: 10px;
}
div.img-placeholder {
  /* https://stackoverflow.com/q/1495407 */
  aspect-ratio: 167/241;
  background-color: lightgray;
}
span.affiliation {
  font-weight: 200;
}
span.topic {
  letter-spacing: -0.27px;
  font-weight: 300;
}
figcaption span.tags {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.1em 0.2em; /* vertical horizontal */
}
</style>
