<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <CallToAction />
  <Header v-if="year" :year="year" />
  <main v-if="year">
    <!-- :key must be on <component>; re-mount when display width changes -->
    <router-view v-slot="{Component}">
      <component :is="Component" v-if="$route.path.includes('Video')" :key="$route.fullPath" />
      <keep-alive v-else>
        <component :is="Component" :key="`${$route.fullPath}+${displayWidth}`" />
      </keep-alive>
    </router-view>
    <Warning />
    <GdprConsent />
  </main>
</template>

<script setup>
const log = (message) => console.log(); // eslint-disable-line
import CallToAction from "@/components/CallToAction/Banner";
import GdprConsent from "@/components/GdprConsent.vue";
import Header from "@/components/Header.vue";
import history from "@/assets/history.json";
import Warning from "@/components/Popup/Warning.vue";
import {useDisplayWidth} from "@/composables/useDisplayWidth";
import {provide, ref, watch} from "vue";
import {useDisplayMode} from "@/composables/useDisplayMode";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import "normalize.css"; // https://stackoverflow.com/q/42119878

import useFocusHandlers from "@/composables/useFocusHandlers.js";
// Call the useFocusHandlers function to set up global focus listeners
useFocusHandlers();

const displayWidth = useDisplayWidth(); // single value returned from composable
const displayMode = useDisplayMode(); // single value returned from composable
const store = useStore();
provide("displayWidth", displayWidth); // provide reactive variable to children
provide("displayMode", displayMode); // provide reactive variable to children
provide("store", store); // provide reactive variable to children
// update selected year and continent
const continent = ref("");
// determine if a year is currently selected (e.g., vizbi.org/2023a)
import {useYear} from "@/composables/useSelectedYear";
const [selectedYear, selectedTimeZone] = useYear(); // single value returned from composable

provide("selectedYear", selectedYear); // provide reactive variable to children
provide("selectedTimeZone", selectedTimeZone); // provide reactive variable to children
const year = ref(selectedYear.value);
const yearAndContinent = () => {
  let newYear = selectedYear.value || history.latestYear;
  if (newYear === year.value) {
    return [year.value, continent.value]; // current values
  }
  let newContinent = history.years[newYear];
  newContinent = newContinent.match(/^[^/]*/)[0].toLowerCase();
  log(`[year, continent] = [${newYear}, ${newContinent}]`);
  if (newContinent !== continent.value) {
    log(`Updating continent in body class list`);
    if (document.body.classList && continent.value) {
      document.body.classList.remove(continent.value);
    }
    document.body.classList.add(newContinent);
  }
  return [newYear, newContinent]; // new values
};
// update document title
const documentTitle = (to) => {
  let defaultName = "Visualizing Biological Data (VIZBI)";
  if (!to.name) return defaultName;
  if (to.name === "AboutYear" && to.params.year) {
    return `VIZBI ${to.params.year}`; // like apple etc
  }
  if (to.name.includes("Video")) return "Videos - VIZBI";
  if (to.params.year) {
    return `${to.name} - VIZBI ${to.params.year}`; // like apple etc
  }
  if (!to.name.match(/Home/)) return `${to.name} - VIZBI`;
  return defaultName;
};
// update when route or selectedYear changes
const route = useRoute();
import {setBackgroundImage} from "@/composables/useBackground";
watch(
  [route, selectedYear],
  // eslint-disable-next-line
  ([to, newselectedYear]) => {
    log(`Route change`);
    if (!to.name) {
      log(`No route name`);
      return;
    }
    [year.value, continent.value] = yearAndContinent();
    document.title = documentTitle(to);
    setBackgroundImage(selectedYear);
  },
  {deep: true, immediate: false} // flush: 'pre', deep: true
);
</script>

<style>
/* not 'scoped' = applies to all components */
body {
  /* https://help.uberflip.com/hc/en-us/articles/360019190171 */
  display: inline-block;
  /* margin: 0; /* https://stackoverflow.com/q/48240969 */
  /* background-color: var(--background-color); */
  /* background-image: linear-gradient(to bottom, var(--top-of-page) 0px, var(--background-color) 450px); */
  color: var(--text-color);
  text-align: left; /* re-set from @/styles/global.css/<html> */
  min-width: 20rem; /* 320px; narrowest width to design with */
  max-width: 56rem; /* 56rem 95rem=6columns; but only up to max-width: 100vw; */
}
</style>
<style scoped>
main {
  /* background-color: white; reset from Background.vue */
  min-height: 70vh;
  padding: 1.5rem 1rem; /* top-bottom right-left */
  background-color: var(--background-color);
  color: var(--text-color);
}
.narrow main {
  padding: 1.5rem 0.8rem; /* top-bottom right-left */
}
/* above loads animations used in Sidebar.vue */
@import "@/styles/show-hide-stage.css";
</style>
