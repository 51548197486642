<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div v-if="host.includes('dev')" id="warning">
    <h1>Under construction!</h1>
    <p>Temporary page used to build a new site.</p>
    <button @click="goHome()">Go to main VIZBI site</button>
  </div>
</template>

<script setup>
let host = window.location.host;
function goHome() {
  window.location.href = "https://vizbi.org";
}
</script>

<style scoped>
/* scoped = CSS limit to this component only */
#warning {
  /* https://stackoverflow.com/q/38144953 */
  position: fixed; /* default position is set relative to viewport  */
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 220px;
  background-color: #fdd200;
  text-align: center;
  padding: 8px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
}
h1 {
  font-size: 1.5rem;
  margin: 0px;
}
p {
  font-size: 0.7rem;
  margin: 0px;
}
</style>
