<template>
  <a :href="`mailto:${email}`">{{ email }}</a>
</template>

<script setup>
import {ref, onMounted} from "vue";
const props = defineProps({
  recipient: {type: String, default: ""},
  domain: {type: String, default: ""}
});
const email = ref(""); // holds assembled email address
const assembleEmail = () => {
  email.value = `${props.recipient}@${props.domain}`;
};
onMounted(assembleEmail); // Assemble email address when component mounted
</script>
