<!-- Copyright: Seán I. O'Donoghue -->
<!-- TODO: add selection coloring to Burger.vue when year != currentYear -->

<template>
  <button title="Open Menu" @click.prevent="openDialog = true">
    <!-- click.stop let's parent (PrimaryDialog.vue) handle the click event -->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <line x1="1" y1="6" x2="23" y2="6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      <line x1="1" y1="12" x2="23" y2="12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      <line x1="1" y1="18" x2="23" y2="18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  </button>
  <PrimaryDialog v-if="openDialog" :views="views" :selected="selected" @close="openDialog = false" />
</template>

<script setup>
const props = defineProps(["views", "selected"]); // eslint-disable-line
import PrimaryDialog from "@/components/Navigation/Primary/Dialog.vue";
import {ref} from "vue";
const openDialog = ref(false);
// open dialog if restarted with '#menu' in hash
import {onMounted} from "vue";
onMounted(() => {
  if (location.hash === "#menu") openDialog.value = true;
});
</script>

<style scoped>
button {
  display: inline-block;
  flex-direction: row;
  justify-content: center; /*space-between space-evenly*/
  align-items: center; /*space-between space-evenly*/
  height: 1.6rem;
  width: 1.6rem;
  z-index: 10;
  border: 0;
  margin: 0px;
  padding: 0.2rem;
  background-color: transparent;
  cursor: pointer;
  pointer-events: all;
  box-shadow: none;
}
svg {
  color: var(--primary-navigation-color);
  margin: 0;
  padding: 0;
}
div.bar {
  opacity: 1;
  z-index: 1;
  min-height: 0.2rem;
  border-radius: 3px;
  /* background-color: white; */
  background-color: var(--primary-navigation-color);
  box-shadow: var(--box-shadow-color);
}
button:hover div.bar {
  background-color: var(--primary-navigation-hover-color);
}
</style>
