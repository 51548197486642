<template>
  <h2>Poster Upload Page</h2>
  <p>Before using this form, you need to first register for the VIZBI conference; in the form below, be sure to use the same email address you used for registration. To be included in the VIZBI conference, posters and Art and Biology submissions need to be uploaded before <b>6 March 2023, 12 noon PDT</b>.</p>
  <p>The <q>Poster + Lightning Talk</q> option allows you to upload a poster image plus up to three PNG images to accompany the lightning talk that you will deliver in person just prior to your poster session.</p>
  <p>The <q>Virtual Poster + Lightning Talk</q> option allows online particants to upload a poster image plus a lightning talk video (max. 60 seconds) that will be played to all delegates just prior to your poster session.</p>
  <p>Using the <q>Art &amp; Biology Artwork</q> option, you can also upload an artistically-inspired image for the Art &amp; Biology event, along with an accompanying description. Each participant can submit only one Art &amp; Biology artwork.</p>
  <p>You can change your contribution simply by uploading a second time, which will over-write your previous submission. Further details on the upload files are available here.</p>
  <PosterUploadForm />
</template>

<script>
import PosterUploadForm from "@/components/PosterUploadForm";
export default {
  components: {PosterUploadForm}
};
</script>

<style scoped>
q {
  font-style: italic;
  quotes: "'" "'";
}
</style>
