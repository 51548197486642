// takes two arrays; returns merged array of unique values

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

import stringify from "json-stringify-safe";
export async function arrayMergeUnique(array1, array2) {
  // return array of only unique values
  // https://stackoverflow.com/q/54090245
  let mergedArray = array1.concat(array2); // merge two arrays
  let primitives = {};
  let objects = [];
  let objectStrings = {};
  let output = [];
  for (const item of mergedArray) {
    if (typeof item === "object") {
      // https://stackoverflow.com/q/1068834
      //objects[stringify(item)] = item;
      objects.push(item);
    } else {
      primitives[item] = item;
    }
  }
  log(`arrayMergeAsync: objects = ${stringify(objects)}`);
  let promises = objects.map(async (object) => {
    let objectString = await stringify(object);
    objectStrings[objectString] = object;
  });
  await Promise.all[promises];
  //output = await output.concat(Object.values(primitives).sort().reverse());
  //output = await output.concat(Object.values(objectStrings));
  output = output.concat(Object.values(primitives).sort().reverse());
  output = output.concat(Object.values(objectStrings));
  log(`arrayMergeAsync: ${stringify(output)}`);
  return output;
}
/* Quokka test:
let [a, b] = [
  [1, 1, 2, 5, "34", 2],
  ["34", { x: 1 }],
];
let c = await arrayMergeUnique(a, b);
console.log(stringify(c));
/* */
