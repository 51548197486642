// Copyright: Seán I. O'Donoghue
// properties from object2 are added to object1
// if the property is an array, the arrays are concatinated

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

import stringify from "json-stringify-safe";
//import { arrayMergeUnique } from "@/composables/useArrayMerge";
import {arrayMergeUnique} from "./useArrayMergeAsync.js";
export async function objectMergeConcatArrays(object1, object2) {
  log(`objectMerge: object1 = ${stringify(object1)}`);
  log(`objectMerge: object2 = ${stringify(object2)}`);
  const promises = Object.keys(object2).map(async (key) => {
    log(`objectMergeAsync: key = ${key}`);
    if (!object1[key]) {
      log(`objectMergeAsync: ${key} not in object1, so add it`);
      object1[key] = object2[key];
      return; // Promise.resolve(1)
    }
    log(`objectMergeAsync: key ${key} present in both objects`);
    // https://stackoverflow.com/q/767486
    if (object1[key].constructor === Array && object2[key].constructor === Array) {
      log(`objectMergeAsync: merge arrays for key = ${key}`);
      object1[key] = await arrayMergeUnique(object1[key], object2[key]);
      return object1[key];
    }
  });
  await Promise.all(promises);
  return object1;
}

/* Quokka test 
let [a, b] = [
  { photos: [1, 2, 3], 2: 5, 34: 2 },
  { photos: [1, "34", 3], sub: { x: 1 } },
];
let c = await objectMergeConcatArrays(a, b);
console.log(stringify(c));
/* */
