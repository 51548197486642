<template>
  <h3><span style="font-weight: 200"> 'Unseen Natural History'</span></h3>
  <!-- <h4>Visual storytelling - from ecosystems to biomolecules</h4> -->
  <!-- <h4>Using animation and cinematography to explain the living world</h4> -->
  <!-- <h4>Animating and photographing the living world</h4> -->
  <p>The 'Unseen Natural History' showcase is an in-person-only event that take place just after the EMBO workshop on ‘Visualizing Biological Data’ (<router-link to="/2023">VIZBI 2023</router-link>). The showcase will feature a conversation between two award winning science communicators — biomedical animator Drew Berry and wildlife filmmaker Martin Dohrn — along with selected screenings of their recent work. Both have dedicated their careers to developing pioneering methods that show aspects of the natural world never seen before. <router-link to="/2023/Registration">Register now</router-link> or read below for details.</p>
  <router-link to="/2023/Registration" title="Click to register">
    <HeroImage file="2023/Showcase/img/banner.jpeg" credit="Martin Dohrn &amp; Drew Berry" />
  </router-link>
  <p>Drew and Martin will discuss the challenges of communicating the complexity of living behaviour across a broad range of scales. This will be followed by a panel discussion and Q&amp;A on the role of film in educating and inspiring audiences with discoveries from the life sciences.</p>
  <p>
    The showcase event is co-organised with EMBL's <a href="https://www.embl.org/about/info/science-society/" noreferrer>Science &amp; Society Programme</a>. The event will be free, and all VIZBI participants are invited, as are interested scientists and the general public from the local community. For any questions, please write to <Email to="vizbi-contact" />.
    <!--To join the Showcase, click the link below to sign-up via Eventbrite.-->
  </p>
  <p>This event has sold out. If you already signed up for the wait-list on Eventbrite we will contact you in the next few days to confirm your place at the event.</p>
  <p>
    <a href="https://www.eventbrite.de/e/science-on-screen-vizbi-showcase-unseen-natural-history-tickets-558556827507" noreferrer>
      <ButtonBespoke :disabled="true" :red="true">SOLD OUT</ButtonBespoke>
    </a>
  </p>
</template>

<script>
import ButtonBespoke from "@/components/ButtonBespoke";
import Email from "@/components/Email";
import HeroImage from "@/components/HeroImage";
export default {
  name: "Index",
  components: {ButtonBespoke, Email, HeroImage}
};
</script>

<style scoped>
h3 {
  display: inline;
}
p button {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
