<!-- begin image src with "./" so it's imported as a vue 'static asset' -->
<!-- https://cli.vuejs.org/guide/html-and-static-assets.html#static-assets-handling -->
<template>
  <a href="https://embl.org" target="_blank">
    <img src="./img/embo-workshop.png" alt="EMBO" title="EMBO" />
  </a>
</template>

<style scoped>
a {
  /* ensures <img> does not overflow focus outline border */
  display: inline-block;
}
</style>
