<template>
  <h3>Registration Form</h3>
  <!-- v-if prevents errors when switching routes -->
  <component :is="page" v-if="$route.name" />
</template>

<script setup>
import {useRoute} from "vue-router";
const route = useRoute();
import {shallowRef} from "vue";
let page = shallowRef();
try {
  if (route.params.year) {
    let year = route.params.year;
    page = shallowRef(require(`@/assets/years/${year}/Registration/Form/Index.vue`).default);
  }
} catch (error) {
  console.error(`Form.vue|${route.params.year} error: ${error}`);
}
</script>

<style scoped>
.iframe_container {
  height: 2300px;
  width: auto;
  padding: 0px;
  margin: 0px;
  text-align: left;
  /* background: url(`@/assets/img/wait.gif`) no-repeat top left; */
  background-position: 10px 30px;
}
iframe {
  height: 1200px; /* 2300px */
  max-width: 800px !important;
  min-width: 400px !important;
  width: 100% !important;
  padding: 0px;
  margin: 0px;
}
</style>
