<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <nav>
    <h3>
      Photos
      <span v-if="totalPhotos && year && photoDataByYear && photoDataByYear[year]"> [{{ photoDataByYear[year].length }}/{{ totalPhotos }}] </span>
      <span v-else-if="totalPhotos"> [{{ totalPhotos }}] </span>
    </h3>
    <div id="right">
      <Dropdown placeholder="Year" :options="years" :selected="year" @update="setYearHere" />
      <!--<ButtonBespoke v-if="year" @click="setYearHere">Show All</ButtonBespoke>-->
    </div>
  </nav>
</template>

<script>
// https://medium.com/@magyarn/simple-photo-app-with-vue-js-axios-and-flickr-api-part-1-e6e84bfe2580
//import ButtonBespoke from '@/components/ButtonBespoke';
import Dropdown from "@/components/Dropdown";
//import stringify from 'json-stringify-safe';
export default {
  name: "Photos",
  components: {Dropdown}, //ButtonBespoke,
  props: {
    totalPhotos: {type: Number, default: 0},
    years: {type: Array, default: () => []},
    photoDataByYear: {type: Object, default: () => {}}
  },
  emits: ["setYear"],
  data() {
    return {
      year: this.$route.params.photoYear || "" // empty string = all years
    };
  },
  methods: {
    // eslint-disable-next-line
    log(message) {
      if (process.env.NODE_ENV === "development") {
        console.log(message); // uncomment to show logs from this component
      }
    },
    setYearHere(year) {
      this.log(`change selected year to: ${year}`);
      if (typeof year === "string") {
        history.pushState({}, null, `/Photos/${year}`);
        this.year = year;
      } else {
        // showAll button returns a push event
        history.pushState({}, null, `/Photos/`);
        this.year = "";
      }
      this.$emit("setYear", this.year);
    }
  }
};
</script>

<style scoped>
nav {
  display: flex;
  justify-content: space-between;
  /* Distribute evenly; 1st item flush with start; last flush with end */
  align-items: center;
  padding: 0px;
  margin: 0px;
}
h3 {
  display: inline;
  margin-top: auto;
  margin-bottom: auto;
}
h3 span {
  font-weight: 100;
  font-size: 1rem;
  vertical-align: middle;
}
.narrow h3 span {
  /* display: none; /* hide stats on narrow displays */
}
#right {
  padding: 0px;
  margin: 0px;
  /*margin-top: 12px;*/
  margin-left: auto;
}
div + button {
  margin-left: 0.6rem;
}
.narrow button,
.narrow button {
  /* matching @/styles/global.css/h2 */
  /*margin-top: 6px; */
  margin-top: 0px;
}
</style>
