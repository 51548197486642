<template>
  <p>The EMBO workshop on Visualizing Biological Data (VIZBI 2023) will be held at the EMBL Advanced Training Centre (ATC), located on the EMBL, Heidelberg campus (<a href="https://goo.gl/maps/RRZMJukFm45CiBBX8">Meyerhofstraße 1, 69117 Heidelberg, Germany</a>). For general information about the ATC and the Heidelberg campus, please see the <a href="https://www.embl.org/about/info/course-and-conference-office/information-for-participants/onsite-information/">on-site information page</a> maintained by EMBL's Courses and Conferences office (CCO).
  </p>
  <!-- https://www.embl.org/about/info/course-and-conference-office/embl-advanced-training-centre-atc/ -->
  <HeroImage file="2023/Venue/img/banner.jpeg" credit="EMBL 2023" />
  <h4>Accommodation</h4>
  <p>Accommodation is not included in the registration fee of the conference. As further changes in our events are possible due to COVID-19, you should book flights, trains and hotels with flexible options and favourable cancellation conditions.</p>
  <p>The hotels below have rooms on hold for participants until <b>Tuesday 28 February 2023</b>, in some cases at special rates. Please quote the booking code VZB23-01 and confirm the exact price of the room with the hotel directly.</p>
  <div id="hotels">
    <a v-for="hotel in hotels" :key="hotel" :href="hotel[1]">
      {{ hotel[0] }}
    </a>
  </div>
  <h4>Getting to EMBL Heidelberg</h4>
  <p>For general information on getting to EMBL Heidelberg visit <a href="https://www.embl.org/about/info/course-and-conference-office/information-for-participants/travel-information/">Public Transportation to the Venue</a>. For information about accommodation and local transportation please refer to the <a href="https://www.embl.org/about/info/course-and-conference-office/information-for-participants/frequently-asked-questions/">EMBL CCO FAQ page</a>.</p>
  <h4>Conference Shuttles Buses</h4>
  <p>EMBL have arranged for a conference shuttle bus service that will be free of charge for participants, and depart from designated bus stops near the hotels to EMBL and back, mornings and evenings. The bus schedule <!-- will be published ahead of the start of the conference and you can see --> is published below. You can see that the bus stops at the following locations:</p>
  <ul>
    <li>Staycity Aparthotel (Speyerer Str. 7)</li>
    <li>Kurfürsten-Anlage (Opposite Main Train Station)</li>
    <li>Premier Inn (Kurfürsten-Anlage 23)</li>
    <li>Leonardo Hotel Heidelberg City Center (Bergheimer Str. 63)</li>
    <li>Neckarmünzplatz (Heidelberg Tourist Information)</li>
    <li>Peterskirche (Bus stop)</li>
    <li>ISG Hotel (Im Eichwald 19)</li>
  </ul>
  <p>View <a href="https://www.google.com/maps/d/embed?mid=1aEqYpFy2y5WiorwNxhHeFCNiY2o&ehbc=2E312F">Conference shuttle bus stops and hotels in a larger map</a>. <!-- Please note that not every bus stop will be used for every event.--></p>
  <p class="centered">
    <a href="/2023/Conference_Bus-01.pdf" download="download">{{ bus_schedule }}</a>
  </p>
  <a href="/2023/Conference_Bus-01.pdf" download="download" :title="bus_schedule">
    <vue-pdf-embed source="/2023/Conference_Bus-01.pdf" />
  </a>
  <p class="centered">
    <a href="/2023/Conference_Bus-01.pdf" download="download">{{ bus_schedule }}</a>
  </p>
</template>

<script>
  import hotelInfo from '@/assets/years/2023/Venue/hotels.json';
  import HeroImage from '@/components/HeroImage.vue';
  import VuePdfEmbed from 'vue-pdf-embed';
  export default {
    components: {HeroImage, VuePdfEmbed},
    data() {
      return {
        hotels: hotelInfo,
        bus_schedule: 'Download Conference Bus Schedule (PDF)'
      };
    }
  };
</script>

<style scoped>
  div#hotels {
    columns: 3;
  }
  div#hotels a {
    display: block;
    margin-bottom: 8px;
  }
  img {
    width: 100%;
  }
  p.centered {
    text-align: center
  }
</style>
